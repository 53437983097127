import {
  Alert,
  ArrowRightIcon,
  ClipboardIcon,
  Divider,
  Group,
  InfoIcon,
  List,
  ListItem,
  MapPinIcon,
  PrimaryButton,
  RepeatIcon,
  ResponsiveBox,
  SecondaryButton,
  SmartphoneIcon,
  Space,
  Stack,
  Text,
  TitleTwo,
  emojiMap,
  useLifecycle,
  useMonitoring,
} from '@shared/components'
import { REFERRAL_PARAM } from '@shared/types'
import React from 'react'
import { useQuery } from 'react-query'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import * as FullStory from '../../../src/common/fullstory'
import { patientApi, referralsApi } from '../../common/api'
import { ContactSupport, LearnMore, MoreOptions, Skeletons } from '../../common/components'
import { useFlags, useQueryParams } from '../../common/hooks'
import { logger } from '../../common/logger'
import { routes } from '../../common/routes'
import { analytics, sendPageEvent, sendTrackEvent } from '../../common/rudderstack'
import {
  getLocalStorageItem,
  getSessionStorageItem,
  removeSessionStorageItem,
  setLocalStorageItem,
} from '../../common/storage'
import { ReferralHowOpheliaWorks, ReferralInsurance, ReferralIntro } from './Referral'

export type CheckedInsuranceLocationStateType = {
  checkedInsurance: boolean
}
export const Welcome = () => {
  const params = useQueryParams()
  const monitoring = useMonitoring()
  const navigate = useNavigate()
  const isReferral = params.has(REFERRAL_PARAM)
  const location = useLocation()
  const locationState = location.state as CheckedInsuranceLocationStateType
  const checkedInsurance = locationState?.checkedInsurance

  const { referralMoreInfo } = useFlags()

  const { data: personalizedReferralData, isLoading: isPersonalizedReferralLoading } = useQuery(
    ...patientApi.getQuery('GET /referral/friend-or-family/:referralId/personalization', {
      params: { referralId: params.get(REFERRAL_PARAM) as string },
    }),
    { enabled: isReferral },
  )
  const showReferrerMessage = personalizedReferralData?.personalizedReferral?.showReferrerMessage
  let personalizedReferralMessage = ''
  let personalizedReferrerName = 'Your friend'
  if (showReferrerMessage && personalizedReferralData?.personalizedReferral) {
    personalizedReferralMessage = personalizedReferralData.personalizedReferral.referrerMessage
    personalizedReferrerName = personalizedReferralData.personalizedReferral.referrerName
  }

  const handleTrackEvents = (referralId: string | null, existingReferralId: string | null) => {
    const data: analytics.apiObject = {}

    if (referralId) {
      data.referralId = referralId
      void referralsApi.addReferralClickEvent(referralId)
      sendTrackEvent('Referral Link Clicked', data)
      FullStory.event('Referral Link Clicked')
      setLocalStorageItem('wxflow_ref', referralId)

      if (existingReferralId && existingReferralId !== referralId) {
        data.existingReferralId = existingReferralId
        FullStory.event('Referral Link Clicked & Existing Referral Found')
        sendTrackEvent('Referral Link Clicked & Existing Referral Found', data)
      }
    } else if (existingReferralId) {
      data.existingReferralId = existingReferralId
      FullStory.event('Existing Referral Found')
      sendTrackEvent('Existing Referral Found', data)
    }
    return data
  }

  useLifecycle({
    onMount: () => {
      const referralIdFromParams = params.get(REFERRAL_PARAM)
      const existingReferralIdFromSessionStorage = getLocalStorageItem('wxflow_ref')
      const provider = getSessionStorageItem('referral_workflow_prompt_response')
      if (provider) {
        removeSessionStorageItem('referral_workflow_prompt_response')
      }

      if (getSessionStorageItem('welcome_call_topics_survey')) {
        removeSessionStorageItem('welcome_call_topics_survey')
      }

      const eventData = handleTrackEvents(
        referralIdFromParams,
        existingReferralIdFromSessionStorage,
      )
      logger.info('Welcome', { tags: { workflow: 'onboarding' } })
      sendPageEvent('Welcome', eventData)
    },
  })

  const onGetStarted = () => {
    monitoring.trace.start({
      key: 'click-get-started-to-welcome-call-scheduled',
    })
    navigate(routes.welcome.children.signup)
  }

  if (isPersonalizedReferralLoading) {
    return <Skeletons />
  }

  const handleClickCheckInsurance = () => {
    sendTrackEvent('Click Check My Insurance On Welcome Page')
    FullStory.event('Click Check My Insurance On Welcome Page')
    navigate(routes.insuranceCoverage.index)
  }

  return (
    <Stack test-id='page:welcome-call' spacing='sm'>
      {showReferrerMessage && (
        <Alert variant='primary' mb='md'>
          <Stack>
            <Text size='md' color={colors => colors.text[2]}>
              {personalizedReferralMessage}
            </Text>
            <Text size='xs' color={colors => colors.text[2]}>
              - {personalizedReferrerName}
            </Text>
          </Stack>
        </Alert>
      )}
      {isReferral && referralMoreInfo && (
        <Stack>
          <ReferralIntro />
          <Divider my='md' />
          <ReferralHowOpheliaWorks />
          <Divider my='md' />
          <ReferralInsurance />
          <Divider my='md' />
        </Stack>
      )}
      <Stack spacing='lg'>
        <Stack spacing='xs'>
          {isReferral ? (
            <TitleTwo>
              {emojiMap['waving hand']} {personalizedReferrerName} wants you to try Ophelia
            </TitleTwo>
          ) : (
            <TitleTwo>{emojiMap['waving hand']} Welcome to myOphelia</TitleTwo>
          )}
          <Text>
            Let&apos;s set you up with a free 15-minute welcome call. First, we have a few questions
            that should take less than 5 minutes to answer.
          </Text>
        </Stack>
        <Stack spacing='sm'>
          <Text>We&apos;ll ask for your:</Text>
          <Space h='xs' />
          <List
            spacing='sm'
            sx={() => ({ paddingLeft: 0 })}
            styles={theme => ({ itemIcon: { color: theme.other.colors.text[1] } })}
          >
            <ListItem icon={<MapPinIcon />}>Location</ListItem>
            <ListItem icon={<SmartphoneIcon />}>Phone number</ListItem>
            <ListItem icon={<RepeatIcon />}>Relationship with opioids</ListItem>
            <ListItem icon={<ClipboardIcon />}>Health insurance (if you have it)</ListItem>
          </List>
        </Stack>
        {!checkedInsurance && (
          <Alert
            icon={<InfoIcon />}
            actionIcon={<ArrowRightIcon />}
            onClick={() => handleClickCheckInsurance()}
            variant='primary'
            title={
              <Text bold color={colors => colors.text[2]}>
                Check my coverage
              </Text>
            }
          >
            <Stack>
              <Text color={colors => colors.text[2]} size='xs'>
                Let&apos;s check your insurance to find out if your care might be covered.
              </Text>
            </Stack>
          </Alert>
        )}
        <ResponsiveBox
          mobile={
            <Stack spacing='lg'>
              <Stack spacing='md' align='center'>
                <PrimaryButton test-id='button:continue@mobile' onClick={onGetStarted} fullWidth>
                  Get started
                </PrimaryButton>
                {/* Referrals do not need to see sign in button */}
                {isReferral ? (
                  <MoreOptions />
                ) : (
                  <SecondaryButton
                    test-id='button:sign-in@mobile'
                    fullWidth
                    component={Link}
                    to={routes.welcome.children.signin}
                  >
                    Sign in
                  </SecondaryButton>
                )}
              </Stack>
              {/* Only show contact support on mobile - desktop has it in the header */}
              <ContactSupport />
              {/* Referrals do not need this because it's the other CTA already */}
              {!isReferral && <LearnMore />}
            </Stack>
          }
          desktop={
            <Group position={isReferral ? 'right' : 'apart'}>
              {/* Referrals do not need this because it's the other CTA already */}
              {!isReferral && <LearnMore />}
              <Group>
                {/* Referrals do not need to see sign in button */}
                {isReferral ? (
                  <MoreOptions />
                ) : (
                  <SecondaryButton
                    test-id='button:sign-in@desktop'
                    component={Link}
                    to={routes.welcome.children.signin}
                  >
                    Sign in
                  </SecondaryButton>
                )}
                <PrimaryButton test-id='button:continue@desktop' onClick={onGetStarted}>
                  Get started
                </PrimaryButton>
              </Group>
            </Group>
          }
        />
      </Stack>
    </Stack>
  )
}
