import { Center, TertiaryButton } from '@shared/components'
import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { routes } from '../routes'
import { sendTrackEvent } from '../rudderstack'

export const RecoverYourAccount = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const navigateToAccountRecovery = () => {
    sendTrackEvent('Recover Your Account Clicked')

    const searchParams = new URLSearchParams(location.search)
    searchParams.set('step', 'account-recovery-options')

    navigate({
      pathname: `${routes.welcome.index}/${routes.welcome.children.signin}`,
      search: searchParams.toString(),
    })
  }

  return (
    <Center>
      <TertiaryButton onClick={navigateToAccountRecovery}>
        Need to recover your account?
      </TertiaryButton>
    </Center>
  )
}
