import { AuthenticationMethod } from '@shared/types'
import { BaseApi, BaseApiConfig, getSettledValue, toTime } from '@shared/utils'
import { Auth, AuthError, AuthErrorCodes, signInWithCustomToken } from 'firebase/auth'
import { isFirebaseError } from '../errors'
import { FirebaseUser } from '../firebase'
import * as FullStory from '../fullstory'
import { logFullstoryError } from '../logger'

const DEFAULT_AUTH_ERROR_MESSAGE = 'Something went wrong, please try again'

/**
 * COMMON AUTH ERROR CODES: https://firebase.google.com/docs/reference/js/v8/firebase.auth.Error#common-error-codes
 * PHONE AUTH ERROR CODES: https://firebase.google.com/docs/reference/js/v8/firebase.auth.PhoneAuthProvider#error-codes
 */
const authErrorCodeMap = new Map<string, string>([
  [AuthErrorCodes.INVALID_PHONE_NUMBER, 'Please make sure that this phone number is valid'],
  [AuthErrorCodes.INVALID_CODE, 'Incorrect code'],
  [
    AuthErrorCodes.TOO_MANY_ATTEMPTS_TRY_LATER,
    "You've tried to verify your phone too many times, try again in 10 minutes",
  ],
  [AuthErrorCodes.CODE_EXPIRED, 'Your verification code has expired'],
  [AuthErrorCodes.TOKEN_EXPIRED, 'Your reset password token has expired'],
  [AuthErrorCodes.INVALID_CUSTOM_TOKEN, 'Your reset password token has expired'],
])

export class AuthApi extends BaseApi {
  _auth: Auth
  _firebaseUser: FirebaseUser | null
  _authTokenTtl: number
  _issuedAtTime: number | null

  constructor({ auth, apiConfig }: { auth: Auth; apiConfig: BaseApiConfig }) {
    super(apiConfig)

    this._auth = auth
    this._authTokenTtl = toTime('1 min').ms()
    this._firebaseUser = null
    this._issuedAtTime = null

    this.axiosInstance.interceptors.request.use(async config => {
      const [idTokenSettledResult] = await Promise.allSettled([this.getIdToken()])

      if (config.headers) {
        const idTokenResult = getSettledValue(idTokenSettledResult)
        if (idTokenResult) {
          config.headers.Authorization = `Bearer ${idTokenResult.token}`
        }
      }

      return config
    })
  }

  set firebaseUser(firebaseUser: FirebaseUser | null) {
    if (firebaseUser) {
      const userContext: {
        id: string
        email?: string
        phoneNumber?: string
        displayName?: string
      } = { id: firebaseUser.uid }

      if (firebaseUser.email) {
        userContext.email = firebaseUser.email
      }

      if (firebaseUser.displayName) {
        userContext.displayName = firebaseUser.displayName
      }

      if (firebaseUser.phoneNumber) {
        userContext.phoneNumber = firebaseUser.phoneNumber
      }

      FullStory.identify(firebaseUser.uid)
    }

    this._firebaseUser = firebaseUser
  }

  signInWithToken = async (token: string) => {
    await signInWithCustomToken(this._auth, token)
  }

  getIdToken = async () => {
    let forceRefresh = false

    if (this._issuedAtTime) {
      forceRefresh = new Date().getTime() - this._issuedAtTime > this._authTokenTtl
    }

    const idTokenResult = await this._firebaseUser?.getIdTokenResult(forceRefresh)

    if (!idTokenResult) {
      return null
    }

    this._issuedAtTime = new Date(idTokenResult.issuedAtTime).getTime()
    return idTokenResult
  }

  getTokenAuthenticationMethod = async (): Promise<AuthenticationMethod> => {
    const token = await this._firebaseUser?.getIdTokenResult()
    if (!token) {
      return undefined
    }
    if (token.signInProvider === 'phone') {
      return 'phone'
    }
    return token.claims.method as AuthenticationMethod
  }

  signOut = async () => {
    try {
      return await this._auth.signOut()
    } catch (err) {
      const error = err as AuthError
      logFullstoryError({
        errorMessage: error.message,
        errorName: error.code,
        errorSummary: 'Auth Error',
      })
      throw error
    }
  }

  parseVerificationCode = (code = '') => {
    const formatted = code.trim()

    return {
      formatted,
      /* eslint-disable-next-line no-magic-numbers */
      isValid: formatted.length === 6,
    }
  }

  getSanitizedErrorMessage = (error: unknown): string => {
    if (isFirebaseError(error)) {
      if (authErrorCodeMap.has(error.code)) {
        return authErrorCodeMap.get(error.code) as string
      }
    }

    return DEFAULT_AUTH_ERROR_MESSAGE
  }
}
