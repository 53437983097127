import { useForm, UseFormReturnType } from '@mantine/form'
import { Box, useBanner, useLifecycle, validateWith } from '@shared/components'
import { phone as formatPhone } from '@shared/utils'
import React, { useCallback, useEffect, useState } from 'react'
import { useMutation, UseMutationResult, useQuery } from 'react-query'
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom'
import { authApi, currentUserApi, patientApi } from '../../../common/api'
import { Skeletons } from '../../../common/components'
import { auth } from '../../../common/firebase'
import { isPhone, isRequired } from '../../../common/forms'
import * as FullStory from '../../../common/fullstory'
import { useAuth, useQueryParams } from '../../../common/hooks'
import { logger } from '../../../common/logger'
import { routes } from '../../../common/routes'
import { removeSessionStorageItem } from '../../../common/storage'
import { SignInForm } from '../types'
import { Verification } from '../Verification'
import { AccountRecoveryContact } from './AccountRecoveryContact'
import { AccountRecoveryOptions } from './AccountRecoveryOptions'
import { ConfirmationEmailSent } from './ConfirmationEmailSent'
import { Password } from './Password'
import { Phone } from './Phone'
import { SetEmail } from './SetEmail'
import { SetPassword } from './SetPassword'

const signinSteps = [
  'phone',
  'verification',
  'set-password',
  'password',
  'set-email',
  'email-sent',
  'account-recovery-options',
  'account-recovery-contact',
] as const

export type SigninStep = (typeof signinSteps)[number]
const STEP_QUERY_PARAM = 'step'

export type SigninProps = {
  setCurrentStep: (step: SigninStep, options?: { replace: boolean }) => void
  form: UseFormReturnType<SignInForm>
  signInWithPhoneNumber: UseMutationResult<void, unknown, string, unknown>
  backToSignInPage: () => void
}

export const Signin = () => {
  const { isAuthorized, hasPassword, hasEmail, isLoading, currentUser } = useAuth()
  const [submittedEmail, setSubmittedEmail] = useState<string>('')
  const { showBanner } = useBanner()
  const query = useQueryParams()
  const [searchParams, setSearchParams] = useSearchParams()
  const navigate = useNavigate()

  useLifecycle({
    onMount: () => {
      removeSessionStorageItem('residence_state')
      removeSessionStorageItem('insurance_provider')
    },
  })

  const currentStep = searchParams.get(STEP_QUERY_PARAM) as SigninStep
  const setCurrentStep = useCallback(
    (step: SigninStep, options: { replace: boolean } = { replace: false }) => {
      setSearchParams({ [STEP_QUERY_PARAM]: step }, options)
    },
    [setSearchParams],
  )

  const accountRecoveryResult = query.get('accountRecoveryResult') as 'success' | 'error' | null
  useEffect(() => {
    if (accountRecoveryResult) {
      showBanner({
        type: accountRecoveryResult,
        label:
          accountRecoveryResult === 'success'
            ? 'Phone number updated'
            : 'Error updating phone number',
      })
    }
  }, [accountRecoveryResult, showBanner])

  /*
   * Account recovery token handling
   * TODO: Delete acct recovery queries from here once customer.io campaign is updated.
   */
  const accountRecoveryTokenId = query.get('accountRecoveryToken')
  useQuery(
    ['currentUserApi.getPasswordResetToken', accountRecoveryTokenId],
    async () => currentUserApi.getPasswordResetToken(accountRecoveryTokenId || ''),
    {
      onSuccess: data => signInFirebaseAuth.mutate(data?.token),
      onError: () => showBanner({ type: 'error', label: 'Your account recovery link is invalid' }),
      enabled: Boolean(accountRecoveryTokenId),
    },
  )

  const updateAccountPhone = useMutation(patientApi.getMutation('PUT /account/recovery'), {
    onSuccess: () => {
      setCurrentStep('phone', { replace: true })
      showBanner({
        type: 'success',
        label: `Phone number updated`,
      })
      logger.info('Successfully updated phone for account recovery')
    },
    onError: error => {
      setCurrentStep('phone', { replace: true })
      showBanner({ type: 'error', label: 'Error updating phone number' })
      logger.error(`Error updating phone for account recovery: ${error}`)
    },
  })

  const signInFirebaseAuth = useMutation(authApi.signInWithToken, {
    onSuccess: () => updateAccountPhone.mutate({}),
    onError: error => showBanner({ type: 'error', label: authApi.getSanitizedErrorMessage(error) }),
  })

  const form = useForm<SignInForm>({
    // State is not used for sign in
    initialValues: { phoneNumber: '' },
    validate: { phoneNumber: validateWith(isRequired, isPhone) },
  })

  const hasConsents = Boolean(
    currentUser?.data?.consents?.privacyPolicy &&
      currentUser?.data?.consents?.telehealthInformedConsent &&
      currentUser?.data?.consents?.termsOfUse &&
      currentUser?.data?.consents?.textMessageConsent,
  )

  useEffect(() => {
    if (
      ['account-recovery-options', 'account-recovery-contact'].includes(currentStep) ||
      accountRecoveryTokenId
    ) {
      return
    }

    if (isAuthorized && !isLoading) {
      if (!hasEmail || !hasConsents) {
        if (currentStep !== 'set-email' && currentStep !== 'email-sent') {
          setCurrentStep('set-email', { replace: true })
        } else if (currentStep === 'email-sent' && !submittedEmail) {
          setCurrentStep('set-email', { replace: true })
        }
      } else if (currentStep !== 'set-password' && !hasPassword) {
        setCurrentStep('set-password', { replace: true })
      } else if (currentStep !== 'password' && hasPassword) {
        setCurrentStep('password', { replace: true })
      }
    } else if (!isAuthorized && !isLoading) {
      // If invalid step found as query param
      if (!signinSteps.includes(currentStep)) {
        FullStory.event('Navigating back to phone step as current step is invalid')
        setCurrentStep('phone', { replace: true })
      }

      // If information missing during verification step
      if (currentStep !== 'phone' && !form.values.phoneNumber) {
        FullStory.event('Navigating back to phone step as no phone number in form state')
        setCurrentStep('phone', { replace: true })
      }
    }
  }, [
    accountRecoveryTokenId,
    currentStep,
    form.values.phoneNumber,
    hasConsents,
    hasEmail,
    hasPassword,
    isAuthorized,
    setCurrentStep,
    isLoading,
    setSubmittedEmail,
    submittedEmail,
  ])

  const { setValues, setFieldValue } = form

  useEffect(() => {
    // Clean information at step
    if (currentStep === 'phone') {
      setValues({ phoneNumber: '' })
    }

    // Clean information at step
    if (currentStep === 'verification') {
      setFieldValue('verificationCode', '')
    }
  }, [currentStep, setFieldValue, setValues])

  const signInWithPhoneNumber = useMutation((phone: string) =>
    currentUserApi.requestOTP({ phone: formatPhone(phone).normalized }),
  )

  const backToSignInPage = async () => {
    await auth.signOut()
    navigate(routes.welcome.children.signin)
  }

  if (isAuthorized && currentUser?.data?.account.requiresOnboarding) {
    /*
     * If the account has never completed an onboarding workflow, sign out and redirect to sign up
     * to get their state for onboarding
     */
    return (
      <Navigate
        replace
        to={`${routes.signout.index}?redirect=${routes.welcome.index}/${routes.welcome.children.signup}`}
      />
    )
  }

  if (currentStep === 'phone') {
    return (
      <Box test-id='page:sign-in-phone-number'>
        <Phone
          form={form}
          setCurrentStep={setCurrentStep}
          signInWithPhoneNumber={signInWithPhoneNumber}
          backToSignInPage={backToSignInPage}
        />
      </Box>
    )
  }

  if (currentStep === 'verification') {
    return (
      <Box test-id='page:sign-in-verification'>
        <Verification
          isSignin
          phoneNumber={form.values.phoneNumber}
          signInWithPhoneNumber={signInWithPhoneNumber}
        />
      </Box>
    )
  }

  if (currentStep === 'set-email') {
    return (
      <SetEmail
        setSubmittedEmail={setSubmittedEmail}
        setCurrentStep={setCurrentStep}
        backToSignInPage={backToSignInPage}
      />
    )
  }

  if (currentStep === 'email-sent') {
    return (
      <ConfirmationEmailSent submittedEmail={submittedEmail} backToSignInPage={backToSignInPage} />
    )
  }

  if (currentStep === 'set-password') {
    return <SetPassword backToSignInPage={backToSignInPage} />
  }

  if (currentStep === 'password') {
    return (
      <Box test-id='page:sign-in-password'>
        <Password
          form={form}
          setCurrentStep={setCurrentStep}
          signInWithPhoneNumber={signInWithPhoneNumber}
          backToSignInPage={backToSignInPage}
        />
      </Box>
    )
  }

  if (currentStep === 'account-recovery-options') {
    return <AccountRecoveryOptions />
  }

  if (currentStep === 'account-recovery-contact') {
    return <AccountRecoveryContact />
  }

  return <Skeletons />
}
