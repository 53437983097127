import { Stack } from '@shared/components'
import {
  PromptContentBanner as PromptContentBannerType,
  PromptContentBlock,
  PromptContentHeader as PromptContentHeaderType,
  PromptContentLikertScale as PromptContentLikertScaleType,
  PromptContentLongInput as PromptContentLongInputType,
  PromptContentMultipleChoice as PromptContentMultipleChoiceType,
  PromptContentNumberInput as PromptContentNumberInputType,
  PromptContentShortInput as PromptContentShortInputType,
  PromptContentSingleSelect as PromptContentSingleSelectType,
  PromptContentWidget as PromptContentWidgetType,
  PromptResponseContextKey,
  PromptResponseModel,
  PromptResponsePayload,
  Prompt as PromptType,
} from '@shared/types'
import React from 'react'
import { PromptContentBanner } from './PromptContentBanner'
import { PromptContentHeader } from './PromptContentHeader'
import { PromptContentLikertScale } from './PromptContentLikertScale'
import { PromptContentLongInput } from './PromptContentLongInput'
import { PromptContentMultipleChoice } from './PromptContentMultipleChoice'
import { PromptContentNumberInput } from './PromptContentNumberInput'
import { PromptContentShortInput } from './PromptContentShortInput'
import { PromptContentSingleSelect } from './PromptContentSingleSelect'
import { PromptContentWidget } from './PromptContentWidget'

const isHeader = (content: PromptContentBlock): content is PromptContentHeaderType => {
  return content.contentType === 'header'
}

const isBanner = (content: PromptContentBlock): content is PromptContentBannerType => {
  return content.contentType === 'banner'
}

const isSingleSelect = (content: PromptContentBlock): content is PromptContentSingleSelectType => {
  return content.contentType === 'response' && content.responseType === 'single_selection'
}

const isMultipleChoice = (
  content: PromptContentBlock,
): content is PromptContentMultipleChoiceType => {
  return content.contentType === 'response' && content.responseType === 'multiple_choice'
}

const isNumberInput = (content: PromptContentBlock): content is PromptContentNumberInputType => {
  return content.contentType === 'response' && content.responseType === 'number_input'
}

const isShortInput = (content: PromptContentBlock): content is PromptContentShortInputType => {
  return content.contentType === 'response' && content.responseType === 'short_input'
}

const isLongInput = (content: PromptContentBlock): content is PromptContentLongInputType => {
  return content.contentType === 'response' && content.responseType === 'long_input'
}

const isLikert = (content: PromptContentBlock): content is PromptContentLikertScaleType => {
  return content.contentType === 'response' && content.responseType === 'likert'
}

const isWidget = (content: PromptContentBlock): content is PromptContentWidgetType => {
  return content.contentType === 'response' && content.responseType === 'widget'
}

type Props = {
  prompt: PromptType
  response: PromptResponsePayload | undefined
  onSubmit: (data: PromptResponseModel['payload']) => void
  onBack?: (() => void) | undefined
}

export const Prompt = ({ prompt, onSubmit, response, onBack }: Props) => {
  return (
    <Stack spacing='md' test-id='page:prompt' id={prompt.contextKey}>
      {prompt.content.map(content => {
        if (isBanner(content)) {
          return <PromptContentBanner key={content.label} data={content} />
        }

        if (isHeader(content)) {
          return (
            <PromptContentHeader
              key={content.primaryText ?? content.secondaryText}
              data={content}
            />
          )
        }

        if (isSingleSelect(content)) {
          return (
            <PromptContentSingleSelect
              key={`response_${content.responseType}`}
              options={content.options}
              // These are simple respones, therefore pass the contextKey directly.
              value={response?.value.contextKey as PromptResponseContextKey | undefined}
              onSubmit={onSubmit}
              onBack={onBack}
            />
          )
        }

        if (isMultipleChoice(content)) {
          return (
            <PromptContentMultipleChoice
              key={`response_${content.responseType}`}
              options={content.options}
              value={response?.value.contextKey as PromptResponseContextKey[]}
              onSubmit={onSubmit}
              onBack={onBack}
            />
          )
        }

        if (isNumberInput(content)) {
          return (
            <PromptContentNumberInput
              key={`response_${content.responseType}`}
              value={response?.value.value as number | undefined}
              onSubmit={onSubmit}
              onBack={onBack}
            />
          )
        }

        if (isShortInput(content)) {
          return (
            <PromptContentShortInput
              key={`response_${content.responseType}`}
              value={response?.value.value as string | undefined}
              onSubmit={onSubmit}
              onBack={onBack}
            />
          )
        }

        if (isLongInput(content)) {
          return (
            <PromptContentLongInput
              key={`response_${content.responseType}`}
              value={response?.value.value as string | undefined}
              onSubmit={onSubmit}
              onBack={onBack}
            />
          )
        }

        if (isLikert(content)) {
          return (
            <PromptContentLikertScale
              key={`response_${content.responseType}`}
              config={content}
              options={content.options}
              value={
                Array.isArray(response?.value.contextKey) ? undefined : response?.value.contextKey
              }
              onSubmit={onSubmit}
              onBack={onBack}
            />
          )
        }

        if (isWidget(content)) {
          return (
            <PromptContentWidget
              key={`response_${content.responseType}`}
              widgetType={content.widgetType}
              response={response}
              onSubmit={onSubmit}
              onBack={onBack}
            />
          )
        }

        return null
      })}
    </Stack>
  )
}
