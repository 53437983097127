import { z } from 'zod'
import { ISOStringSchema, YYYYMMDDSchema } from '../../../dates'
import { ApiSchemas } from '../../utils'

export const ListPatientsForClinicReqRes = {
  req: z.object({
    params: z.object({
      clinicId: z.string(),
    }),
  }),
  res: z.object({
    data: z.array(
      z.object({
        patientId: z.string(),
        patientName: z.string(),
        confirmedAt: ISOStringSchema.nullish(),
        nextFollowUpDate: YYYYMMDDSchema.nullish(),
        attendedAt: ISOStringSchema.nullish(),
        enrollmentId: z.string(),
      }),
    ),
  }),
}

export const RemovePatientReqRes = {
  req: z.object({
    params: z.object({
      patientId: z.string(),
      clinicId: z.string(),
    }),
  }),
  res: z.object({
    data: z.null(),
  }),
}

export const ConfirmPatientReqRes = {
  req: z.object({
    body: z.object({
      enrollmentId: z.string(),
    }),
  }),
  res: z.object({
    data: z.null(),
  }),
}

export const ListClinicsReqRes = {
  req: z.object({}),
  res: z.object({
    data: z.array(
      z.object({
        oid: z.string(),
        datetimeStart: ISOStringSchema,
        datetimeEnd: ISOStringSchema,
        clinicianId: z.string(),
        clinicianFirstName: z.string(),
        clinicianLastName: z.string(),
        timezone: z.string(),
      }),
    ),
  }),
}

export const GetClinicReqRes = {
  req: z.object({
    params: z.object({
      clinicId: z.string(),
    }),
    query: z.object({
      patientId: z.string().optional(),
    }),
  }),
  res: z.object({
    data: z.object({
      oid: z.string(),
      datetimeStart: ISOStringSchema,
      datetimeEnd: ISOStringSchema,
      clinicianId: z.string(),
      clinicianFirstName: z.string(),
      clinicianLastName: z.string(),
      timezone: z.string(),
    }),
  }),
}

export const CreateVisitForPatientReqRes = {
  req: z.object({
    params: z.object({
      patientId: z.string(),
      clinicId: z.string(),
    }),
    body: z.object({
      enrollmentId: z.string(),
    }),
  }),
  res: z.object({
    data: z.object({
      visitId: z.number(),
    }),
  }),
}

export const CreateClinicReqRes = {
  req: z.object({
    body: z.object({
      isActive: z.boolean(),
      datetimeStart: ISOStringSchema,
      datetimeEnd: ISOStringSchema,
      clinicianId: z.string(),
      timezone: z.string(),
      zoomLink: z.string(),
      secondaryClinicianId: z.string().nullable(),
      greeterId: z.string().nullable(),
    }),
  }),
  res: z.object({
    data: z.null(),
  }),
}

export const UpdateClinicReqRes = {
  req: z.object({
    params: z.object({
      clinicId: z.string(),
    }),
    body: z.object({
      isActive: z.boolean(),
      datetimeStart: ISOStringSchema,
      datetimeEnd: ISOStringSchema,
      clinicianId: z.string(),
      timezone: z.string(),
      zoomLink: z.string(),
      secondaryClinicianId: z.string().nullable(),
      greeterId: z.string().nullable(),
    }),
  }),
  res: z.object({
    data: z.null(),
  }),
}

export const DeleteClinicReqRes = {
  req: z.object({
    params: z.object({
      clinicId: z.string(),
    }),
  }),
  res: z.object({
    data: z.null(),
  }),
}

/**
 * This is not truly a list endpoint. But for the sake of
 * normalized naming, we'll call it that. Can change later.
 */
export const ListDropInClinicEnrollmentsReqRes = {
  req: z.object({
    query: z.object({
      patientId: z.string(),
    }),
  }),
  res: z.object({
    data: z
      .object({
        oid: z.string(),
        confirmedAt: ISOStringSchema.nullish(),
        clinic: z.object({
          datetimeStart: ISOStringSchema,
          datetimeEnd: ISOStringSchema,
          clinicianId: z.string(),
          clinicianFirstName: z.string(),
          clinicianLastName: z.string(),
          timezone: z.string(),
        }),
      })
      .nullable(),
  }),
}

export const DropInClinicRouteSchemas = {
  'GET /drop-in-clinics': ListClinicsReqRes,
  'GET /drop-in-clinic-enrollments': ListDropInClinicEnrollmentsReqRes,
  'POST /drop-in-clinics': CreateClinicReqRes,
  'GET /drop-in-clinics/:clinicId': GetClinicReqRes,
  'PUT /drop-in-clinics/:clinicId': UpdateClinicReqRes,
  'DELETE /drop-in-clinics/:clinicId': DeleteClinicReqRes,
  'GET /drop-in-clinics/:clinicId/patients': ListPatientsForClinicReqRes,
  'POST /drop-in-clinics/confirm': ConfirmPatientReqRes,
  'DELETE /drop-in-clinics/:clinicId/patients/:patientId': RemovePatientReqRes,
  'POST /drop-in-clinics/:clinicId/patients/:patientId/visit': CreateVisitForPatientReqRes,
} satisfies ApiSchemas
