import { useForm } from '@mantine/form'
import {
  ArrowRightIcon,
  Center,
  ExternalLinkText,
  Group,
  PrimaryButton,
  ResponsiveBox,
  Stack,
  TertiaryButton,
  Text,
  TextInput,
  TitleTwo,
  useBanner,
  useLifecycle,
  validateWith,
} from '@shared/components'
import { phone } from '@shared/utils'
import React, { useState } from 'react'
import { useMutation } from 'react-query'
import { Link } from 'react-router-dom'
import * as EmailSentAnimation from '../assets/complete.json'
import { patientApi } from '../common/api'
import { ContactSupport } from '../common/components'
import AnimationModal from '../common/components/AnimationModal'
import { isEmail, isRequired } from '../common/forms'
import * as FullStory from '../common/fullstory'
import { useAuth, useFlags, useQueryParams } from '../common/hooks'
import { routes } from '../common/routes'
import { sendPageEvent } from '../common/rudderstack'

type EmailForm = {
  email: string
}

export const AccountRecoveryRequest = () => {
  const [showEmailSentAnimation, setShowEmailSentAnimation] = useState(false)
  const { showBanner } = useBanner()
  const { currentUser } = useAuth()
  const query = useQueryParams()
  const interstitialMode = query.get('interstitial')
  const { accountRecoveryReorg } = useFlags()

  useLifecycle({
    onMount: () => {
      sendPageEvent('Account Recovery Request')
      FullStory.event('Account Recovery Request Visible')
    },
  })

  const form = useForm<EmailForm>({
    initialValues: { email: '' },
    validate: {
      email: validateWith(isRequired, isEmail, (value: string | undefined) =>
        currentUser?.data?.personalData.phone &&
        currentUser?.data?.personalData?.email?.toLowerCase().trim() === value?.toLowerCase().trim()
          ? 'Try the original email you used to set up your account'
          : undefined,
      ),
    },
  })

  const requestAccountRecoveryEmail = useMutation(
    patientApi.getMutation('POST /account/recovery'),
    {
      onSuccess: () => setShowEmailSentAnimation(true),
      onError: () => showBanner({ type: 'error', label: 'Error requesting email' }),
    },
  )

  const submit = () => {
    if (form.validate().hasErrors) {
      return
    }

    requestAccountRecoveryEmail.mutate({ data: { email: form.values.email } })
  }

  const { isLoading } = requestAccountRecoveryEmail

  return (
    <Stack test-id='page:account-recovery' spacing='lg'>
      <Group spacing='sm'>
        <TitleTwo>
          {interstitialMode ? 'Already an Ophelia patient?' : 'Last step: confirm your email'}
        </TitleTwo>
        <Text>
          If we have an account associated with your email address, we&apos;ll send you an email.
          Click the link in the email to {interstitialMode ? 'update' : 'finish updating'} your
          account phone number to {phone(currentUser?.data?.personalData.phone).formatted}
        </Text>
      </Group>
      <TextInput
        test-id='input:email'
        label='Email'
        type='email'
        placeholder='Email'
        maxLength={256}
        disabled={isLoading}
        {...form.getInputProps('email')}
      />
      <ResponsiveBox
        mobile={
          <Stack align='center' justify='center'>
            <PrimaryButton
              test-id='button:submit@mobile'
              fullWidth
              onClick={submit}
              loading={isLoading}
            >
              Confirm email & update account
            </PrimaryButton>
            {interstitialMode && (
              <Stack my='lg'>
                <Text>Not a patient?</Text>
                <TertiaryButton
                  fullWidth
                  rightIcon={<ArrowRightIcon />}
                  component={Link}
                  to={`${routes.signout.index}?redirect=${routes.welcome.index}/${routes.welcome.children.signup}`}
                >
                  Get started
                </TertiaryButton>
              </Stack>
            )}
            <ContactSupport />
          </Stack>
        }
        desktop={
          <Group position='apart'>
            {interstitialMode && (
              <Group position='center'>
                <Text>Not a patient?</Text>
                <TertiaryButton
                  rightIcon={<ArrowRightIcon />}
                  component={Link}
                  to={`${routes.signout.index}?redirect=${routes.welcome.index}/${routes.welcome.children.signup}`}
                >
                  Get started
                </TertiaryButton>
              </Group>
            )}

            <PrimaryButton test-id='button:submit@desktop' onClick={submit} loading={isLoading}>
              Send email
            </PrimaryButton>
          </Group>
        }
      />
      {accountRecoveryReorg && (
        <Center>
          <Text>
            {`If you've changed your email address recently, text `}

            <ExternalLinkText href='sms:2155852144'>(215) 585-2144</ExternalLinkText>
            {' for support'}
          </Text>
        </Center>
      )}
      <AnimationModal
        onClose={() => setShowEmailSentAnimation(false)}
        opened={showEmailSentAnimation}
        animation={EmailSentAnimation}
        title='Email sent'
        tagline='If we had your email address on file, you will receive an email from us shortly.'
      />
    </Stack>
  )
}
