import {
  ArrowLeftIcon,
  Divider,
  ExternalLinkText,
  Group,
  Stack,
  TertiaryButton,
  Text,
  TitleTwo,
  useLifecycle,
} from '@shared/components'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { EMAIL_SUPPORT, OPHELIA_PHONE_FORMATTED, SMS_SUPPORT } from '../../../common/components'
import * as FullStory from '../../../common/fullstory'
import { sendTrackEvent } from '../../../common/rudderstack'

export const AccountRecoveryContact = () => {
  const navigate = useNavigate()

  useLifecycle({
    onMount: () => {
      sendTrackEvent('Account Recovery Contact Visible')
      FullStory.event('Account Recovery Contact Visible')
    },
  })

  return (
    <Stack spacing='md'>
      <TitleTwo>Reach out to us for next steps</TitleTwo>
      <Divider my='sm' />
      <Text>{`We'll need some more information to recover your account.`}</Text>
      <Text>
        Call us at
        <ExternalLinkText href={`sms:${SMS_SUPPORT}`}>
          <TertiaryButton>{OPHELIA_PHONE_FORMATTED}</TertiaryButton>
        </ExternalLinkText>
        or email us at
        <ExternalLinkText href={`mailto:${EMAIL_SUPPORT}`}>
          <TertiaryButton>{EMAIL_SUPPORT}</TertiaryButton>
        </ExternalLinkText>
        {`and we'll recover your account with your new information as soon as we can.`}
      </Text>
      <Divider my='sm' />
      <Group position='apart'>
        <TertiaryButton
          test-id='button:back@desktop'
          leftIcon={<ArrowLeftIcon />}
          onClick={() => navigate(-1)}
        >
          Back
        </TertiaryButton>
      </Group>
    </Stack>
  )
}
