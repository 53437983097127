import { Center, TertiaryButton } from '@shared/components'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { routes } from '../routes'
import { sendTrackEvent } from '../rudderstack'

export const ReturningToOphelia = () => {
  const navigate = useNavigate()

  const navigateToSignIn = () => {
    sendTrackEvent('Returning to Ophelia Clicked')
    navigate(`${routes.welcome.index}/${routes.welcome.children.signin}`)
  }

  return (
    <Center>
      <TertiaryButton onClick={navigateToSignIn}>Returning to Ophelia?</TertiaryButton>
    </Center>
  )
}
