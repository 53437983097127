import { z } from 'zod'
import { ApiSchemas } from '../../utils'
import { stateAbbreviations } from '../../../states'

export const ProviderInfoSchema = z.object({
  licenses: z.array(
    z.object({
      isCurrentlyPracticing: z.boolean(),
      state: z.enum([stateAbbreviations[0]!, ...stateAbbreviations.slice(1)]),
    }),
  ),
})

const GetEmployeeReqRes = {
  req: z.object({
    params: z.object({
      employeeId: z.string(),
    }),
  }),
  res: z.object({
    data: z.object({
      oid: z.string(),
      name: z.string(),
      legalFirstName: z.string().optional(),
      legalLastName: z.string().optional(),
      status: z.enum(['currentEmployee', 'formerEmployee']),
      providerInfo: ProviderInfoSchema.optional(),
      intakeStates: z
        .array(z.enum([stateAbbreviations[0]!, ...stateAbbreviations.slice(1)]))
        .optional(),
    }),
  }),
}

const UpdateEmployeeReqRes = {
  req: z.object({
    params: z.object({
      employeeId: z.string(),
    }),
    body: z.object({
      name: z.string().optional(),
      legalFirstName: z.string().optional(),
      legalLastName: z.string().optional(),
      status: z.enum(['currentEmployee', 'formerEmployee']).optional(),
      providerInfo: ProviderInfoSchema.optional(),
      intakeStates: z
        .array(z.enum([stateAbbreviations[0]!, ...stateAbbreviations.slice(1)]))
        .optional(),
    }),
  }),
  res: z.object({
    data: z.null(),
  }),
}

const SyncCalendarReqRes = {
  req: z.object({
    params: z.object({
      employeeId: z.string(),
    }),
    body: z.object({
      oauthToken: z.string(),
    }),
  }),
  res: z.object({
    data: z.null(),
  }),
}

export const EmployeesRouteSchemas = {
  'GET /employees/:employeeId': GetEmployeeReqRes,
  'PUT /employees/:employeeId': UpdateEmployeeReqRes,
  'POST /employees/:employeeId/sync-calendar': SyncCalendarReqRes,
} satisfies ApiSchemas
