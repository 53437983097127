import { useForm } from '@mantine/form'
import {
  Checkbox,
  CheckboxGroup,
  Loader,
  PrimaryButton,
  Stack,
  Text,
  TitleTwo,
  useBanner,
  useLifecycle,
} from '@shared/components'
import { getOpheliaHttpError } from '@shared/types'
import { dayjs, template } from '@shared/utils'
import React from 'react'
import { useParams } from 'react-router-dom'
import { ContactSupport } from '../common/components'
import { useMyMutation, useMyQuery, usePortalDims } from '../common/hooks'
import { GoToThePortalButton } from '../schedule/pages'

const AlreadyConfirmedDropInClinic = (props: {
  dayOfWeek: string
  startHour: string
  endHour: string
  clinicianName: string
}) => {
  const { showBanner } = useBanner()

  useLifecycle({
    onMount: () => {
      showBanner({
        type: 'success',
        label: 'Drop-in visit confirmed',
      })
    },
  })

  const title = template(
    'Your follow-up visit at our drop-in clinic for {dayOfWeek}, {startHour} - {endHour} EDT with {clinicianName} is confirmed',
    {
      dayOfWeek: props.dayOfWeek,
      startHour: props.startHour,
      endHour: props.endHour,
      clinicianName: props.clinicianName,
    },
  )

  return (
    <Stack>
      <TitleTwo>{title}</TitleTwo>
      <Text>
        We&apos;ll send you a Zoom link as we get closer to the drop-in start time. Feel free to
        join anytime within those hours. Please note that the clinician might be finishing up with
        another patient.
      </Text>
      <GoToThePortalButton />
      <ContactSupport text='Need help? Text us at' />
    </Stack>
  )
}

const ConfirmDropInClinic = (props: {
  dayOfWeek: string
  startHour: string
  endHour: string
  clinicianName: string
  refetch: () => void
}) => {
  const params = useParams<{ enrollmentId: string }>()
  const { showBanner, cleanBanner } = useBanner()
  const { isMobile } = usePortalDims()
  const confirmClinic = useMyMutation('POST /drop-in-clinic/:id/confirm')

  const form = useForm<{ consents: string[] }>({
    initialValues: {
      consents: [],
    },
    validate: {
      consents: val => {
        if (val.length !== 2) {
          return 'Required'
        }
      },
    },
  })

  const title = template(
    'Confirm your follow-up visit at our drop-in clinic on {dayOfWeek}, {startHour} - {endHour} EDT with {clinicianName}',
    {
      dayOfWeek: props.dayOfWeek,
      startHour: props.startHour,
      endHour: props.endHour,
      clinicianName: props.clinicianName,
    },
  )

  const onSubmit = async () => {
    cleanBanner()

    if (form.validate().hasErrors) {
      return
    }

    try {
      await confirmClinic.mutateAsync({
        params: {
          id: params.enrollmentId as string,
        },
      })
      props.refetch()
    } catch (error) {
      showBanner({
        type: 'error',
        label: getOpheliaHttpError(error, 'Unable to confirm. Please reach out to support.'),
      })
    }
  }

  return (
    <Stack>
      <TitleTwo>{title}</TitleTwo>
      <Text>
        You can join the zoom link anytime within those hours to see a clinician for follow-up
        evaluation.
      </Text>
      <CheckboxGroup {...form.getInputProps('consents')}>
        <Checkbox
          value='free_from_distraction'
          label='I will be free from distraction and in a quiet place'
        />
        <Checkbox
          value='permit_students'
          label="I understand and agree to permit the students working with the clinician to participate in my medical care during my appointment, including, where appropriate, providing direct medical care to me under the clinician's direct supervision."
        />
      </CheckboxGroup>
      <PrimaryButton
        loading={confirmClinic.isLoading}
        onClick={onSubmit}
        fullWidth={isMobile}
        mt='xs'
      >
        Confirm drop-in visit
      </PrimaryButton>
      <ContactSupport text='Need help? Text us at' />
    </Stack>
  )
}

export const DropInClinicApp = () => {
  const params = useParams<{ enrollmentId: string }>()

  const {
    data: clinic,
    isFetching,
    isError,
    refetch,
  } = useMyQuery('GET /drop-in-clinic/:id', {
    params: {
      id: params.enrollmentId as string,
    },
  })

  if (isFetching) {
    return (
      <Stack h='50vh' align='center' justify='center'>
        <Loader />
      </Stack>
    )
  }

  if (isError || !clinic?.data) {
    return (
      <Stack align='center'>
        <Text>Unable to get drop-in clinic information. Please reach out to support.</Text>
        <GoToThePortalButton />
        <ContactSupport text='Need help? Text us at' />
      </Stack>
    )
  }

  const { confirmedAt, datetimeStart, datetimeEnd, clinicianName } = clinic.data

  const dayOfWeek = dayjs(datetimeStart).tz('America/New_York').format('MMM D YYYY').toUpperCase()
  const startHour = dayjs(datetimeStart).tz('America/New_York').format('h:mma')
  const endHour = dayjs(datetimeEnd).tz('America/New_York').format('h:mma')

  if (confirmedAt) {
    return (
      <AlreadyConfirmedDropInClinic
        dayOfWeek={dayOfWeek}
        startHour={startHour}
        endHour={endHour}
        clinicianName={clinicianName}
      />
    )
  }

  return (
    <ConfirmDropInClinic
      dayOfWeek={dayOfWeek}
      startHour={startHour}
      endHour={endHour}
      clinicianName={clinicianName}
      refetch={() => refetch()}
    />
  )
}
