import { UseFormReturnType } from '@mantine/form'
import {
  PrimaryButton,
  SurveyFormType,
  SurveySelect,
  Text,
  Textarea,
  TitleThree,
  useLifecycle,
} from '@shared/components'
import React from 'react'
import { usePortalDims, usePortalMutation } from '../../common/hooks'
import { getSessionStorageItem, removeSessionStorageItem } from '../../common/storage'

export const SurveyData = [
  {
    label: 'Insurance/pricing',
    value: 'insurance_pricing',
  },
  {
    label: 'Telehealth',
    value: 'telehealth',
  },
  {
    label: 'Appointment scheduling',
    value: 'appointment_scheduling',
  },
  {
    label: 'Drug testing policy',
    value: 'drug_testing_policy',
  },
  { label: 'Something else...', value: 'other' },
] as const

export const WelcomeCallTopicsSurvey = ({
  form,
  handleSubmit,
}: {
  form: UseFormReturnType<SurveyFormType>
  handleSubmit: (options: { skipErrorChecking: boolean }) => Promise<void>
}) => {
  const { isMobile } = usePortalDims()
  const postSegmentEvent = usePortalMutation('POST /segment-events')

  useLifecycle({
    onUnmount: () => {
      const surveyStorageItem = getSessionStorageItem('welcome_call_topics_survey')
      if (surveyStorageItem === 'submitted') {
        removeSessionStorageItem('welcome_call_topics_survey')
      }
    },
  })

  const showSubmit = form.values.response

  const enableSubmit =
    (showSubmit && form.values.response !== 'other') ||
    (form.values.freeForm && form.values.freeForm.length > 5)

  if (getSessionStorageItem('welcome_call_topics_survey') === 'submitted') {
    return <TitleThree>Thank you for your response!</TitleThree>
  }

  return (
    <>
      <TitleThree>What would you like to discuss during your call?</TitleThree>
      <Text>
        Your concerns and goals are important, and we want to make sure we cover what matters most
        to you.
      </Text>
      <SurveySelect
        disabled={postSegmentEvent.isLoading}
        aria-label={`Tell us what you'd like to discuss during your welcome call`}
        clearable
        placeholder='Select one...'
        data={SurveyData}
        maxDropdownHeight={275}
        {...form.getInputProps('response')}
      />
      {form.values.response === 'other' && (
        <Textarea
          disabled={postSegmentEvent.isLoading}
          aria-label='Please tell us more'
          placeholder='Please tell us more...'
          maxLength={75}
          autosize
          {...form.getInputProps('freeForm')}
        />
      )}
      {showSubmit && (
        <PrimaryButton
          onClick={() => handleSubmit({ skipErrorChecking: false })}
          disabled={!enableSubmit}
          loading={postSegmentEvent.isLoading}
          fullWidth={isMobile}
        >
          Submit
        </PrimaryButton>
      )}
    </>
  )
}
