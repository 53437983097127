import { ISOString } from '../dates'
import {
  ConsultationCallTaskType,
  InboundCommunicationsTaskType,
  OutageTaskType,
  TaskCategory,
  TaskType,
} from '../emrTaskTypes'
import { DatabaseMetadata } from '../models'
import { OtherString } from '../utils'
import { Employee } from './employee'

export const EMRTaskStatuses = ['open', 'snoozed', 'closed'] as const
export type EMRTaskStatus = (typeof EMRTaskStatuses)[number]

export const getEMRTaskStatusLabel = (status: EMRTaskStatus) => {
  return {
    open: 'Open',
    snoozed: 'Snoozed',
    closed: 'Closed',
  }[status]
}

export const EMRTaskPods = [
  'pes',
  'pss',
  'fc',
  'lcc',
  'scc',
  'tn',
  'legal',
  'ec',
  'lec',
  'rn',
  'engineer',
  'product',
  'ocp',
  'spc',
] as const
export type EMRTaskPod = (typeof EMRTaskPods)[number]

export type EMRTaskPodLabel<T extends 'pod' | 'role' = 'pod' | 'role'> = {
  label: string
  shortLabel: string
  type: T
  value: Employee['role'][]
}

export const EMRTaskPodLabels: Record<EMRTaskPod, EMRTaskPodLabel> = {
  pes: {
    label: 'Patient engagement',
    shortLabel: 'PES',
    type: 'role',
    value: ['patientEngagementSpecialist', 'leadPatientEngagementSpecialist'],
  },
  pss: {
    label: 'Patient support',
    shortLabel: 'PSS',
    type: 'role',
    value: ['patientSupportSpecialist', 'leadPatientSupportSpecialist'],
  },
  fc: {
    label: 'Financial counselors',
    shortLabel: 'FC',
    type: 'role',
    value: ['financialCounselor'],
  },
  lcc: {
    label: 'Lead CCs',
    shortLabel: 'LCC',
    type: 'role',
    value: ['leadPatientEngagementSpecialist', 'leadPatientSupportSpecialist'],
  },
  scc: {
    label: 'Senior CCs',
    shortLabel: 'SCC',
    type: 'role',
    value: [
      'patientEngagementSpecialist',
      'patientSupportSpecialist',
      'leadPatientEngagementSpecialist',
      'leadPatientSupportSpecialist',
    ],
  },
  tn: {
    label: 'Triage nurses',
    shortLabel: 'TN',
    type: 'role',
    value: [
      'tn',
      // NCMs (ie CCMs) help TNs with OCP coverage
      'ncm',
      'ncm_tn',
    ],
  },
  legal: { label: 'Legal', shortLabel: 'Legal', type: 'role', value: ['legal'] },
  ec: {
    label: 'Enrollment coordinators',
    shortLabel: 'EC',
    type: 'role',
    value: ['enrollmentCoordinator', 'leadEnrollmentCoordinator'],
  },
  lec: {
    label: 'Lead enrollment coordinators',
    shortLabel: 'LEC',
    type: 'role',
    value: ['leadEnrollmentCoordinator'],
  },
  rn: {
    label: 'Resource navigators',
    shortLabel: 'Resource',
    type: 'role',
    value: ['resourceNavigator'],
  },
  engineer: {
    label: 'Engineering',
    shortLabel: 'Eng',
    type: 'role',
    value: ['engineer'],
  },
  product: {
    label: 'Product',
    shortLabel: 'Prod',
    type: 'role',
    value: ['product'],
  },
  ocp: {
    label: 'OCP',
    shortLabel: 'OCP',
    type: 'role',
    value: ['pc', 'spc'],
  },
  spc: {
    label: 'Supervising clinicians',
    shortLabel: 'SC',
    type: 'role',
    value: ['spc'],
  },
}

export const getPodOptions = () =>
  Object.entries(EMRTaskPodLabels).map(([value, { label }]) => ({
    value,
    label,
  }))

export const getEMRTaskPodShortLabel = (pod: EMRTaskPod | undefined): string | undefined => {
  return pod ? EMRTaskPodLabels[pod]?.shortLabel : undefined
}

export const EMR_TASK_PRIORITIES = [1, 2, 3, 4, 5] as const
export type EMRTaskPriority = (typeof EMR_TASK_PRIORITIES)[number]

export const OUTAGE_TASK_PRIORITIES = [6, 7, 8] as const
export type OutageTaskPriority = (typeof OUTAGE_TASK_PRIORITIES)[number]

export const INBOUND_COMMUNICATIONS_TASK_PRIORITIES = [5] as const
export type InboundCommunicationsTaskPriority =
  (typeof INBOUND_COMMUNICATIONS_TASK_PRIORITIES)[number]

// When adding a new feature, be sure to add to relevant OUTAGE_TASKS below
export type OutageFeature =
  | 'emr'
  | 'my'
  | 'scheduling'
  | 'chart'
  | 'billing'
  | 'issue_tracker'
  | 'welcome_flow'
  | 'portal'
  | 'referrals'
  | 'prescriptions'

export const OUTAGE_FEATURE_MAP: Record<OutageFeature, string> = {
  emr: 'EMR',
  my: 'myOphelia',
  scheduling: 'Scheduling',
  chart: 'Chart',
  billing: 'Billing/RCM',
  issue_tracker: 'Issue tracker',
  welcome_flow: 'Welcome flow',
  portal: 'Portal',
  referrals: 'Referrals',
  prescriptions: 'Prescriptions',
}

export const OUTAGE_TASKS: Record<
  OutageTaskPriority,
  { label: string; shortLabel: string; features: OutageFeature[] }
> = {
  6: {
    label: 'SEV 3',
    shortLabel: 'SEV3',
    features: [
      'scheduling',
      'chart',
      'billing',
      'issue_tracker',
      'welcome_flow',
      'portal',
      'referrals',
      'prescriptions',
    ],
  },
  7: {
    label: 'SEV 2',
    shortLabel: 'SEV2',
    features: [
      'scheduling',
      'chart',
      'billing',
      'issue_tracker',
      'welcome_flow',
      'portal',
      'referrals',
      'prescriptions',
    ],
  },
  8: { label: 'SEV 1', shortLabel: 'SEV1', features: ['emr', 'my'] },
}

export type EMRTaskCounts = Partial<Record<EMRTaskStatus, number>>

export const EMRTaskNoteTypes = [
  'description',
  'note_added',
  'status_changed',
  'assignee_changed',
] as const
export type EMRTaskNoteType = (typeof EMRTaskNoteTypes)[number]

type EMRTaskNoteBase = {
  employeeId: 'system' | OtherString
  createdAt: string
}

export type EMRTaskNoteDecorated<T extends EMRTaskNoteType = EMRTaskNoteType> = EMRTaskNote<T> & {
  metadata: {
    employeeName: string
    assigneeBeforeName?: string
    assigneeAfterName?: string
  }
}
// @deprecated - moved to Luna
export type EMRTaskNote<T extends EMRTaskNoteType = EMRTaskNoteType> = DatabaseMetadata &
  EMRTaskNoteModel<T>
export type EMRTaskNoteModel<T extends EMRTaskNoteType = EMRTaskNoteType> = EMRTaskNoteBase &
  (T extends 'assignee_changed'
    ? {
        type: 'assignee_changed'
        assigneeBefore?: string
        assigneeAfter?: string
      }
    : T extends 'status_changed'
    ? {
        type: 'status_changed'
        statusBefore: EMRTaskStatus
        statusAfter: EMRTaskStatus
      }
    : T extends 'note_added'
    ? {
        type: 'note_added'
        text: string
        status: EMRTaskStatus
      }
    : {
        type: 'description'
        text: string
      })

export const WINBACK_CALL_TASK_OUTCOMES = [
  'Answered, booked',
  'Answered, needs more time',
  'Answered, not interested',
  'Missed',
  'Voicemail',
  'Not in service',
  'Canceled',
] as const
type WinbackCallTaskOutcomes = (typeof WINBACK_CALL_TASK_OUTCOMES)[number]

export type EMRTaskResults<
  T extends OutageTaskType | ConsultationCallTaskType | InboundCommunicationsTaskType | TaskType =
    | OutageTaskType
    | ConsultationCallTaskType
    | InboundCommunicationsTaskType
    | TaskType,
> = T extends 'winback_call'
  ? {
      callOutcome?: {
        label: 'Call outcome'
        value: WinbackCallTaskOutcomes | null
        optional: false
      }
      nextWinbackCallDatetime?: {
        label: 'Schedule next winback call (optional)'
        value: ISOString | null
        optional: true
      }
    }
  : never

export type LinkedTasks = {
  /**
   * `related` holds generically related task IDs in the future,
   *  we may add more specific types of relationships
   */
  related: {
    id: EMRTask['oid']
  }[]
}

// @deprecated - moved to Luna
export type EMRTaskModel<
  T extends OutageTaskType | ConsultationCallTaskType | InboundCommunicationsTaskType | TaskType =
    | OutageTaskType
    | ConsultationCallTaskType
    | InboundCommunicationsTaskType
    | TaskType,
> = {
  patientId: string | null
  // If employeeId is '', this task is unassigned
  employeeId: string
  updatedAt: string
  createdAt: string
  // Who created this task? 'system' means it was auto-created.
  createdBy: 'system' | OtherString
  priority: T extends TaskType
    ? EMRTaskPriority
    : T extends ConsultationCallTaskType
    ? EMRTaskPriority
    : T extends OutageTaskType
    ? OutageTaskPriority
    : T extends InboundCommunicationsTaskType
    ? InboundCommunicationsTaskPriority
    : never
  status: EMRTaskStatus
  /**
   * Most patient-specific tasks will not have a title, as one can be derived
   * from the patient's name + the task's type.
   *
   * However, non-patient-specific tasks (like outage reports and bugs) may include
   * a user-specific title for descriptive purposes and searchability
   */
  title?: string
  pod?: EMRTaskPod
  remindAt?: string
  /*
   * Some tasks will collect additional outcome data when being closed out.
   * This results data will be concatenated onto the wrap-up description in a
   * bulleted list.
   */
  results?: EMRTaskResults<T>
  linkedTasks?: LinkedTasks
  category?: TaskCategory
  type: T
} & (T extends ConsultationCallTaskType
  ? {
      details: {
        appointmentId: number
        scheduledFor: string
        /**
         * @welcomeCallStandbyExperiment
         * Experiment started on 5/9/24 to allow patients who
         * schedule welcome calls for later to indicate whether
         * they would like to be notified of earlier appointments
         */
        onStandby?: boolean
      }
    }
  : T extends OutageTaskType
  ? {
      details: {
        /**
         * The feature affected by this outage
         */
        feature: OutageFeature
        /**
         * The start time of this outage
         */
        startTime: string
        /**
         * The end time of this outage
         */
        endTime?: string
      }
    }
  : T extends InboundCommunicationsTaskType
  ? {
      /*
       * Hide comms tasks from task summaries like the Patient Journey
       * and used in downstream analytics
       */
      category: 'communications'
      details: {
        /*
         * The unique identifier of the SMS, call, or email which
         * initiated this task. For example, if this was a task created
         * for a new incoming SMS, this would contain the Twilio SMS SID.
         */
        initialCommunicationExternalId: string
      }
    }
  : {})

export type EMRTask<
  T extends ConsultationCallTaskType | OutageTaskType | InboundCommunicationsTaskType | TaskType =
    | ConsultationCallTaskType
    | OutageTaskType
    | InboundCommunicationsTaskType
    | TaskType,
> = EMRTaskModel<T> & DatabaseMetadata

export type NewEMRTaskBody<
  T extends ConsultationCallTaskType | OutageTaskType | InboundCommunicationsTaskType | TaskType =
    | ConsultationCallTaskType
    | OutageTaskType
    | InboundCommunicationsTaskType
    | TaskType,
> = Omit<EMRTaskModel<T>, 'updatedAt' | 'createdAt' | 'createdBy' | 'remindAt' | 'status'> & {
  minutesTriaging?: number
  description: string
  setSystemGenerated?: boolean
}

export type EMRTaskMetadata = {
  patientFirstName: string
  patientLastName: string
  patientPhone: string
  patientState: string
  employeeName: string
  notesCount: number
  filteredNotesCount: number
  primaryClinicianFullName: string
  isReferredPatient: boolean
}

export type EMRTaskDecorated = EMRTask & { metadata: EMRTaskMetadata }

export type EMRTaskTimeEntryModel = {
  createdAt: string
  employeeId: string
  minutes: number
  /** isBillableCoCM is set to true when the patient was active on the CoCM Registry at the time of the activity */
  isBillableCoCM: boolean
}

export type EMRTaskTimeEntry = EMRTaskTimeEntryModel & DatabaseMetadata
