import { getSessionStorageItem } from '../storage'
import { useCallMeNowQueueStatus } from './use-call-me-now-queue-status'

export const useIsQualifiedForCareTransfer = () => {
  const { isQueueOpen } = useCallMeNowQueueStatus()
  // const { cliniciansParticipatingInCareTransferExperiment } = useFlags()

  // get states where clinicians are participating

  const activeStates = ['New York', 'Pennsylvania']

  const residenceState = getSessionStorageItem('residence_state') || ''

  return {
    isQualifiedForCareTransfer: activeStates.includes(residenceState) && isQueueOpen,
  }
}
