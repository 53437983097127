import { Stack } from '@shared/components'
import React from 'react'
import { PromptContentNavigation } from '../PromptContentNavigation'
import { WidgetProps } from '../PromptContentWidget'

/*
 * type ScheduleIntakeVisitForm = {
 * TODO build form type
 * }
 */

export const ScheduleIntakeVisit = ({ onBack }: WidgetProps) => {
  // TODO build widget (use schedule welcome call widget as reference)

  return (
    <Stack spacing='lg' test-id='content:how-to-get-started'>
      {/* TODO build widget */}
      <PromptContentNavigation onBack={onBack} onSubmit={() => {}} />
    </Stack>
  )
}
