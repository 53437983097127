import { useForm } from '@mantine/form'
import {
  Divider,
  ExternalLinkText,
  HomeIcon,
  PrimaryButton,
  Stack,
  SurveyFormType,
  TertiaryButton,
  Text,
  TitleTwo,
  useBanner,
  useLifecycle,
  useMonitoring,
} from '@shared/components'
import { dayjs } from '@shared/utils'
import React from 'react'
import { useQuery } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'
import { patientApi } from '../../common/api'
import { SMS_SUPPORT, Skeletons } from '../../common/components'
import { auth } from '../../common/firebase'
import * as Fullstory from '../../common/fullstory'
import {
  useAuth,
  useFlags,
  useLDClient,
  usePortalDims,
  usePortalMutation,
} from '../../common/hooks'
import { useReferralPartner } from '../../common/hooks/use-partner'
import { logger } from '../../common/logger'
import { routes } from '../../common/routes'
import { sendIdentifyEvent, sendPageEvent, sendTrackEvent } from '../../common/rudderstack'
import { setSessionStorageItem } from '../../common/storage'
import { SurveyData, WelcomeCallTopicsSurvey } from './WelcomeCallTopicsSurvey'

export const WelcomeCallScheduled = () => {
  const { currentUser, isAuthorized } = useAuth()
  const { showBanner } = useBanner()
  const { isPartnerRoute, partner, partnerPath } = useReferralPartner()
  const { workflowSessionId } = useParams<{ workflowSessionId: string }>()
  const navigate = useNavigate()
  const { ldClient } = useLDClient()
  const monitoring = useMonitoring()
  const { isMobile } = usePortalDims()
  const { shouldSendWelcomeCallTopicMessage, shouldSeeWelcomeCallConfirmationPageSurvey } =
    useFlags()
  const patientId = currentUser?.data?.oid

  const getAppointmentQuery = () => {
    if (currentUser?.data?.statuses?.patient === 'discharged') {
      return isAuthorized
        ? patientApi.getQuery('GET /appointments/reenrollment')
        : patientApi.getQuery('GET /referral/appointments/reenrollment', {
            query: { workflowSessionId: workflowSessionId as string },
          })
    }
    return isAuthorized
      ? patientApi.getQuery('GET /appointments/consultation')
      : patientApi.getQuery('GET /referral/appointments/consultation', {
          query: { workflowSessionId: workflowSessionId as string },
        })
  }

  const query = getAppointmentQuery()

  const consultationCallQuery = useQuery(query[0], query[1], {
    onSuccess: () => {
      showBanner({ type: 'success', label: 'Welcome call confirmed' })
      if (isAuthorized) {
        monitoring.trace.end({
          key: 'click-get-started-to-welcome-call-scheduled',
        })
      }
    },
  })

  const handleStartTasks = () => {
    // Submit form values even if the user didn't click the official Submit button
    void handleSurveyFormSubmit({ skipErrorChecking: true })

    sendTrackEvent('Pre-WC Start Tasks Button Clicked')
    navigate(routes.portal.index)
  }

  useLifecycle({
    onMount: () => {
      if (ldClient) {
        ldClient.track('Welcome Call Scheduled')
      }
      if (partner) {
        sendPageEvent(`Welcome Call Scheduled For ${partner.name} Referral`)
        Fullstory.event('Welcome Call Scheduled For Partner Referral', {
          partnerName: `${partner.name}`,
        })
      }
      logger.info('Welcome Call Scheduled', { tags: { workflow: 'onboarding' } })
    },
  })

  const surveyForm = useForm<SurveyFormType>({
    validateInputOnBlur: false,
    initialValues: {
      response: '',
      freeForm: '',
    },
    validate: values => {
      const requireFreeform = values.response === 'other'

      if (requireFreeform) {
        return {
          response: values.response ? null : 'Please select one',
          freeForm: values.freeForm ? null : 'Required',
        }
      }

      return {
        response: values.response ? null : 'Please select one',
        freeForm: null,
      }
    },
  })

  const postSegmentEvent = usePortalMutation('POST /segment-events')

  const handleSurveyFormSubmit = async ({ skipErrorChecking }: { skipErrorChecking: boolean }) => {
    if (!skipErrorChecking) {
      const { hasErrors } = surveyForm.validate()

      if (hasErrors) {
        return
      }
    }

    const welcomeCallTopic = {
      ...surveyForm.values,
      response: SurveyData.find(
        data => data.value === surveyForm.values.response,
      )?.label?.toLowerCase(),
      shouldSendWelcomeCallTopicMessage,
    }

    if (welcomeCallTopic.response && patientId) {
      if (surveyForm.values.response && surveyForm.values.response !== 'other') {
        sendIdentifyEvent({
          uid: patientId,
          data: { welcomeCallTopic },
        })
      }

      await postSegmentEvent.mutateAsync(
        {
          data: {
            event: 'Welcome Call Topic Survey Submitted',
            properties: { ...surveyForm.values, shouldSendWelcomeCallTopicMessage },
          },
        },
        {
          onSuccess: () => {
            setSessionStorageItem('welcome_call_topics_survey', 'submitted')
          },
        },
      )
    }
  }

  if (consultationCallQuery.isLoading || !consultationCallQuery.data) {
    return <Skeletons />
  }

  if (consultationCallQuery.data.callMeNow) {
    if (isAuthorized) {
      return (
        <Stack>
          <TitleTwo>
            {currentUser?.data?.personalData.firstName}, expect a call within the next 20-30 minutes
            from (215) 585-2144
          </TitleTwo>
          <Divider my='md' />
          {shouldSeeWelcomeCallConfirmationPageSurvey && (
            <WelcomeCallTopicsSurvey form={surveyForm} handleSubmit={handleSurveyFormSubmit} />
          )}
          <TitleTwo>Get started on your intake tasks</TitleTwo>
          <Text>
            This is similar to the paperwork you&apos;d fill out the first time you visit an
            in-person doctor. And it&apos;s a great way for us to get acquainted with you before
            your welcome call. (If you get stuck or don&apos;t have time to finish, that&apos;s
            okay!)
          </Text>
          <PrimaryButton fullWidth={isMobile} onClick={handleStartTasks}>
            Start tasks
          </PrimaryButton>
        </Stack>
      )
    }

    return (
      <Stack>
        <TitleTwo>Expect a call within the next 20-30 minutes from (215) 585-2144</TitleTwo>
        <Divider my='md' />
        {shouldSeeWelcomeCallConfirmationPageSurvey && (
          <WelcomeCallTopicsSurvey form={surveyForm} handleSubmit={handleSurveyFormSubmit} />
        )}
        <TitleTwo>Get started on your intake tasks</TitleTwo>
        <Text>
          This is similar to the paperwork you&apos;d fill out the first time you visit an in-person
          doctor. And it&apos;s a great way for us to get acquainted with you before your welcome
          call. (If you get stuck or don&apos;t have time to finish, that&apos;s okay!)
        </Text>
        <PrimaryButton fullWidth={isMobile} onClick={handleStartTasks}>
          Start tasks
        </PrimaryButton>
      </Stack>
    )
  }

  const consultationCallDate = dayjs(consultationCallQuery.data.datetime)

  return (
    <Stack test-id='page:welcome-call-confirmed'>
      <TitleTwo>
        {`We'll give you a call at ${consultationCallDate.format(
          'h:mma z',
        )} on ${consultationCallDate.format('dddd MMM D YYYY')}`}
      </TitleTwo>
      <Text>
        If you&apos;d like to reschedule, cancel, or have any questions, text{' '}
        <ExternalLinkText href={`sms:${SMS_SUPPORT}`}>(215) 585-2144</ExternalLinkText>.
      </Text>
      <Divider my='md' />
      {shouldSeeWelcomeCallConfirmationPageSurvey && (
        <WelcomeCallTopicsSurvey form={surveyForm} handleSubmit={handleSurveyFormSubmit} />
      )}
      {isPartnerRoute ? (
        <TertiaryButton
          leftIcon={<HomeIcon />}
          onClick={async () => {
            if (isAuthorized) {
              await auth.signOut()
            }

            navigate(`${routes.welcome.index}${partnerPath}`)
          }}
        >
          Start a new referral
        </TertiaryButton>
      ) : (
        <>
          <Divider my='md' />
          <TitleTwo>Get started on your intake tasks</TitleTwo>
          <Text>
            This is similar to the paperwork you&apos;d fill out the first time you visit an
            in-person doctor. And it&apos;s a great way for us to get acquainted with you before
            your welcome call. (If you get stuck or don&apos;t have time to finish, that&apos;s
            okay!)
          </Text>
          <PrimaryButton fullWidth={isMobile} onClick={handleStartTasks}>
            Start tasks
          </PrimaryButton>
        </>
      )}
    </Stack>
  )
}
