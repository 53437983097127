import {
  Modal,
  PrimaryButton,
  SecondaryButton,
  Stack,
  Text,
  useLifecycle,
} from '@shared/components'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { auth } from '../../firebase'
import * as FullStory from '../../fullstory'
import { useAuth } from '../../hooks'
import { routes } from '../../routes'
import { sendTrackEvent } from '../../rudderstack'

type PromptSignoutModalProps = {
  signoutModalIsOpen: boolean
  closeSignoutModal: () => void
}

export const PromptSignoutModal = ({
  signoutModalIsOpen,
  closeSignoutModal,
}: PromptSignoutModalProps) => {
  const navigate = useNavigate()
  const { isAuthorized } = useAuth()

  useLifecycle({
    onMount: () => {
      sendTrackEvent('Prompt Signout Modal Visible')
      FullStory.event('Prompt Signout Modal Visible')
    },
  })

  const signout = async () => {
    if (isAuthorized) {
      sendTrackEvent('Signed Out From Workflow Prompt')
      FullStory.event('Signed Out From Workflow Prompt')
      await auth.signOut()
    }

    navigate(`${routes.welcome.index}`)
  }

  return (
    <Modal title='Would you like to exit?' onClose={closeSignoutModal} opened={signoutModalIsOpen}>
      <Stack p='md' spacing='md'>
        <Text>
          {`If you exit, you'll need to enter and verify your phone number again to schedule a welcome call.`}
        </Text>
        <PrimaryButton onClick={signout} fullWidth>
          Yes
        </PrimaryButton>
        <SecondaryButton onClick={closeSignoutModal} fullWidth>
          No
        </SecondaryButton>
      </Stack>
    </Modal>
  )
}
