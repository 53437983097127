import { useIdle } from '@mantine/hooks'
import {
  ArrowLeftIcon,
  Group,
  HeartIcon,
  SecondaryButton,
  Stack,
  TertiaryButton,
  Text,
  useBanner,
  useLifecycle,
  useMantineTheme,
} from '@shared/components'
import { Prompt, WorkflowSession, WorkflowSessionMetadata } from '@shared/types'
import { phone, toTime } from '@shared/utils'
import React, { useCallback, useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { Navigate, useNavigate, useParams } from 'react-router-dom'
import { patientApi } from '../../common/api'
import { Skeletons } from '../../common/components'
import { WorkflowParams, WorkflowPrompt } from '../../common/components/prompt/WorkflowPrompt'
import * as Fullstory from '../../common/fullstory'
import { useAuth, useFlags, usePortalDims, usePortalQuery } from '../../common/hooks'
import { useCallMeNowQueueStatus } from '../../common/hooks/use-call-me-now-queue-status'
import { useReferralPartner } from '../../common/hooks/use-partner'
import { routes } from '../../common/routes'
import { sendPageEvent } from '../../common/rudderstack'
import {
  getLocalStorageItem,
  getSessionStorageItem,
  removeSessionStorageItem,
  setSessionStorageItem,
} from '../../common/storage'
import { SkipOnboardingDrawer } from '../skipOnboarding/SkipOnboardingDrawer'

export const OnboardingWorkflowPrompt = () => {
  const theme = useMantineTheme()
  const navigate = useNavigate()
  const { showBanner } = useBanner()
  const { currentUser, isAuthorized } = useAuth()
  const residenceState =
    currentUser?.data?.homeData?.state || getSessionStorageItem('residence_state')
  const onboarding = getSessionStorageItem('onboarding_workflow')
  const { sessionId, promptId } = useParams<WorkflowParams>()
  const { isPartnerRoute, partnerId, partner, partnerPath } = useReferralPartner()
  const { isMobile } = usePortalDims()
  const referralIdFromSessionStorage = getLocalStorageItem('wxflow_ref')
  const { isQueueAvailable } = useCallMeNowQueueStatus()
  const { callMeNowCta } = useFlags()

  const promptQuery = usePortalQuery(
    isAuthorized
      ? 'GET /workflow/session/:sessionId/prompt/:promptId'
      : 'GET /referral/workflow/session/:sessionId/prompt/:promptId',
    {
      params: {
        sessionId: sessionId as WorkflowSession['oid'],
        promptId: promptId as Prompt['oid'],
      },
    },
    {
      enabled: Boolean(sessionId && promptId),
      onSuccess: data => {
        Fullstory.event('Onboarding Workflow Prompt Visible', {
          promptContextKey: `${data?.status !== 'complete' && data?.prompt.contextKey}`,
          isCmnNudgeOffered: `${callMeNowCta ? 'yes' : 'no'}`,
        })
      },
    },
  )
  const promptData = promptQuery.data
  const promptContextKey = promptData?.status !== 'complete' && promptData?.prompt.contextKey

  const isReferredByHealthcareProviderPrompt =
    promptContextKey === 'referred_by_healthcare_provider'
  const isScheduleWelcomeCallPrompt = promptContextKey === 'schedule_welcome_call'

  const { data: personalizedReferralData, isLoading: isPersonalizedReferralLoading } = useQuery(
    ...patientApi.getQuery('GET /referral/friend-or-family/:referralId/personalization', {
      params: { referralId: referralIdFromSessionStorage as string },
    }),
    { enabled: Boolean(referralIdFromSessionStorage) },
  )
  const personalizedReferrerName = personalizedReferralData?.personalizedReferral
    ?.showReferrerMessage
    ? personalizedReferralData?.personalizedReferral?.referrerName
    : 'Your friend'

  useEffect(() => {
    if (
      referralIdFromSessionStorage &&
      personalizedReferralData &&
      personalizedReferralData.personalizedReferral?.showReferrerMessage
    ) {
      showBanner({
        type: 'success',
        icon: <HeartIcon />,
        label: `${personalizedReferrerName} thinks you can do it together`,
        persistAfterNavigation: true,
      })
    }
  }, [
    referralIdFromSessionStorage,
    personalizedReferralData,
    personalizedReferrerName,
    theme.other.colors.background,
    showBanner,
  ])

  useLifecycle({
    onMount: () => {
      if (partner) {
        sendPageEvent(`Partner Referral Onboarding - ${partner.name}`)
        Fullstory.event('Partner Referral Onboarding', {
          partnerName: `${partner.name}`,
        })
      }
    },
  })

  const logout = useCallback(() => {
    removeSessionStorageItem('onboarding_workflow')
    removeSessionStorageItem('residence_state')
    removeSessionStorageItem('insurance_provider')

    if (isAuthorized) {
      if (partnerPath) {
        navigate(`${routes.signout.index}?redirect=${routes.welcome.index}${partnerPath}`)
      } else {
        navigate(
          `${routes.signout.index}?redirect=${routes.welcome.index}/${routes.welcome.children.signup}`,
        )
      }
    } else {
      navigate(`${routes.welcome.index}${partnerPath}`)
    }
  }, [isAuthorized, navigate, partnerPath])

  // If no residence state is present, they skipped the step where a state needs to be selected, send them back
  useEffect(() => {
    if (!residenceState) {
      logout()
    }
  }, [logout, residenceState])

  const [skipOnboardingDrawerVisible, setSkipOnboardingDrawerVisible] = useState(false)
  const [skipOnboardingButtonVisible, setSkipOnboardingButtonVisible] = useState(false)
  const [skipOnboardingDrawerHasBeenShown, setSkipOnboardingDrawerHasBeenShown] = useState(false)

  const idleTimer = useIdle(toTime('3 sec').ms(), {
    initialState: false,
  })

  useEffect(() => {
    if (
      !callMeNowCta ||
      !promptId ||
      !isQueueAvailable ||
      isScheduleWelcomeCallPrompt ||
      isReferredByHealthcareProviderPrompt
    ) {
      setSkipOnboardingButtonVisible(false)
      setSkipOnboardingDrawerVisible(false)
    } else if (
      idleTimer &&
      isQueueAvailable &&
      promptId &&
      !skipOnboardingDrawerVisible &&
      !skipOnboardingDrawerHasBeenShown &&
      !isScheduleWelcomeCallPrompt &&
      !isReferredByHealthcareProviderPrompt
    ) {
      setSkipOnboardingDrawerVisible(true)
    }
  }, [
    idleTimer,
    skipOnboardingDrawerVisible,
    skipOnboardingDrawerHasBeenShown,
    promptId,
    callMeNowCta,
    isScheduleWelcomeCallPrompt,
    isReferredByHealthcareProviderPrompt,
    isQueueAvailable,
  ])

  const closeRequestACallNowDrawer = () => {
    setSkipOnboardingDrawerVisible(false)
    setSkipOnboardingButtonVisible(true)
    setSkipOnboardingDrawerHasBeenShown(true)
  }

  if (!residenceState || isPersonalizedReferralLoading) {
    return <Skeletons />
  }

  // Don't create a new workflow session if the same login session
  if (!sessionId && onboarding) {
    if (onboarding.partner) {
      return (
        <Navigate
          replace
          to={`${routes.onboarding.index}/${onboarding.partner}/${onboarding.id}/prompt`}
        />
      )
    }

    return <Navigate replace to={`${routes.onboarding.index}/${onboarding.id}/prompt`} />
  }

  const getMetadata = () => {
    const metadata: WorkflowSessionMetadata = {}
    const referralWorkflowSessionId = getSessionStorageItem('referral_workflow_prompt_response')
    if (partnerId) {
      metadata.referralPartnerId = partnerId
    }
    if (referralWorkflowSessionId) {
      metadata.referralWorkflowPromptResponse = referralWorkflowSessionId
    }

    return metadata
  }

  const { maskedFormat } = phone(currentUser?.data?.personalData.phone)

  const showRequestACallNowButton =
    callMeNowCta &&
    isQueueAvailable &&
    !skipOnboardingDrawerVisible &&
    skipOnboardingButtonVisible &&
    isMobile

  return (
    <>
      <Stack>
        {isPartnerRoute &&
          (isAuthorized ? (
            <Group noWrap spacing='xs'>
              <Text>{maskedFormat}</Text>
              <TertiaryButton test-id='button:sign-out' onClick={logout}>
                Not your number?
              </TertiaryButton>
            </Group>
          ) : (
            <TertiaryButton
              test-id='button:start-over'
              leftIcon={<ArrowLeftIcon />}
              onClick={logout}
            >
              Start over
            </TertiaryButton>
          ))}

        <WorkflowPrompt
          metadata={getMetadata()}
          showProgressNumbers={false}
          workflowVariant={isPartnerRoute ? 'referral-partner' : undefined}
          workflowApi={isAuthorized ? 'workflow' : 'referral'}
          workflowType='onboarding'
          prefix={`${routes.onboarding.index}${partnerPath}`}
          onComplete={async () => {
            removeSessionStorageItem('onboarding_workflow')
            removeSessionStorageItem('residence_state')
            removeSessionStorageItem('insurance_provider')
            removeSessionStorageItem('referral_workflow_prompt_response')

            if (isAuthorized) {
              await currentUser?.refetch()
            }

            navigate(`${routes.scheduled.index}${partnerPath}/${sessionId}`)
          }}
          showBackButton
          onGetSessionId={sessionId =>
            setSessionStorageItem('onboarding_workflow', {
              id: sessionId,
              partner: partnerId ?? '',
            })
          }
        />
        {showRequestACallNowButton && (
          <SecondaryButton
            mt='lg'
            fullWidth={isMobile}
            onClick={() => setSkipOnboardingDrawerVisible(true)}
          >
            Request a call now
          </SecondaryButton>
        )}
      </Stack>
      {callMeNowCta && isQueueAvailable && (
        <SkipOnboardingDrawer
          skipOnboardingButtonVisible={skipOnboardingButtonVisible}
          setSkipOnboardingDrawerVisible={setSkipOnboardingDrawerVisible}
          opened={skipOnboardingDrawerVisible}
          onClose={closeRequestACallNowDrawer}
        />
      )}
    </>
  )
}
