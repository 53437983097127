// eslint-disable-next-line no-restricted-imports
import {
  Checkbox as MantineCheckbox,
  CheckboxProps as MantineCheckboxProps,
  MantineSize,
  Text,
  useMantineTheme,
} from '@mantine/core'
import React, { ReactNode } from 'react'
import { useInlineInputThemeStyles } from '../inline-inputs.helpers'
export type CheckboxProps = Omit<
  MantineCheckboxProps,
  'styles' | 'icon' | 'label' | 'description' | 'radius' | 'value' | 'fz'
> & {
  label: ReactNode
  radius?: MantineSize
  value?: string
  fz?: MantineSize
  iconless?: boolean
  description?: string
}

export const Checkbox = ({
  disabled = false,
  value,
  checked,
  radius,
  fz = 'md',
  iconless,
  description,
  explanation,
  ...props
}: CheckboxProps & { explanation?: string | undefined }) => {
  const { styles, inputStyle } = useInlineInputThemeStyles({
    scale: 1 / 2,
    iconless: Boolean(iconless),
    description: Boolean(description),
    iconRadius: 'sm',
    inputRadius: radius,
    fz,
  })

  const {
    other: { colors, fontFamilies },
  } = useMantineTheme()

  return (
    <>
      <MantineCheckbox
        {...props}
        /*
         * Adds data-checked attribute to root in order to style labels,
         * this is already added by the CheckboxGroup but not by checked prop.
         */
        wrapperProps={checked ? { ['data-checked']: true } : {}}
        checked={checked}
        value={value}
        disabled={disabled}
        description={description}
        styles={{ input: inputStyle, ...styles }}
      />
      {explanation && (
        <Text ff={fontFamilies.bold} color={colors.text[1]}>
          {explanation}
        </Text>
      )}
    </>
  )
}
