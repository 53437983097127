import {
  FileRejection,
  Group,
  InputError,
  TertiaryButton,
  Text,
  TrashIcon,
  Upload,
} from '@shared/components'
import React, { ReactNode, useCallback, useState } from 'react'
import { useMutation } from 'react-query'
import { filesApi, storageApi } from '../../api'
import { useGcsFile } from '../../hooks'
import { PreviewPhoto } from '../file/Photo'

export type UploadPhotoProps = {
  label: string
  footer?: string | undefined
  value: string | undefined
  setError: (error?: ReactNode) => void
  onChange: (fileId?: string) => void
  disabled?: boolean
  'test-id'?: string
  onboarding?: boolean
} & InputError

export const UploadPhoto = ({
  onChange,
  setError,
  label,
  value,
  disabled = false,
  error,
  warning,
  success,
  ...rest
}: UploadPhotoProps) => {
  const [loading, setLoading] = useState(false)

  const { file, setFile, isFileMissing } = useGcsFile(value === 'loading' ? undefined : value, () =>
    setError(`Unable to load your file, please try again later.`),
  )

  const getFileWriteMutation = useMutation(filesApi.getFileWrite)

  const writeFile = getFileWriteMutation

  const addFileToStorage = useMutation(storageApi.addFile, { retry: 1 })
  const deleteFile = useMutation(filesApi.deleteFile)

  const onDrop = useCallback(
    async ([file]: [File, ...File[]]) => {
      setError()
      onChange('loading')
      setFile(file)
      setLoading(true)

      try {
        const { fileId, signedUrl } = await writeFile.mutateAsync()
        await addFileToStorage.mutateAsync({ signedUrl, file })

        onChange(fileId)
      } catch {
        onChange()
        setFile(undefined)
        setError(`Failed to upload image, please try again.`)
      }

      setLoading(false)
    },
    [setError, setFile, setLoading, onChange, addFileToStorage, writeFile],
  )

  const onReject = useCallback(
    (files: FileRejection[]) => setError(files[0]?.errors[0]?.message),
    [setError],
  )

  const isLoading = loading || isFileMissing

  const onRemove = useCallback(() => {
    if (isLoading) {
      return
    }

    if (value) {
      deleteFile.mutate(value)
    }

    setFile(undefined)
    onChange()
    setError()
  }, [setError, setFile, onChange, value, deleteFile, isLoading])

  return (
    <Upload
      primaryText='Upload photo'
      test-id={rest['test-id']}
      subText='JPEG, PNG or PDF under 20MB'
      accept={{ 'image/*': ['.png', '.jpeg', '.jpg', '.pdf'] }}
      maxSizeMB={20}
      onDrop={onDrop}
      error={error}
      warning={warning}
      success={success}
      loading={isLoading}
      disabled={disabled}
      onReject={onReject}
      label={
        <Group noWrap position='apart'>
          <Text>{label}</Text>
          {!isLoading && file && (
            <TertiaryButton
              test-id='button:remove'
              onClick={onRemove}
              disabled={disabled}
              rightIcon={<TrashIcon />}
            >
              Remove
            </TertiaryButton>
          )}
        </Group>
      }
    >
      {file && <PreviewPhoto file={file} />}
    </Upload>
  )
}
