// eslint-disable-next-line no-restricted-imports
import {
  MultiSelect as MantineMultiSelect,
  MultiSelectProps as MantineMultiSelectProps,
} from '@mantine/core'

export type MultiSelectProps = MantineMultiSelectProps

export const MultiSelect = MantineMultiSelect

MultiSelect.displayName = 'MultiSelect'
