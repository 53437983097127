import { z } from 'zod'
import { ApiSchemas } from '../../..'
import { nonEmptyString } from '../../../zodUtils'

const ListSmartPhrasesReqRes = {
  req: z.object({}),
  res: z.object({
    data: z.array(
      z.object({
        oid: z.string(),
        name: z.string(),
        categoryName: z.string().nullable(),
        updatedAt: z.string(),
        text: z.string(),
      }),
    ),
  }),
}

const GetSmartPhraseReqRes = {
  req: z.object({
    params: z.object({
      id: z.string(),
    }),
  }),
  res: z.object({
    data: z.object({
      oid: z.string(),
      employeeId: z.string(),
      categoryIds: z.array(z.string()).nullable(),
      text: z.string(),
    }),
  }),
}

const CreateSmartPhraseReqRes = {
  req: z.object({
    body: z.object({
      name: nonEmptyString,
      categoryId: z.string().nullable(),
      text: nonEmptyString,
    }),
  }),
  res: z.object({
    data: z.object({
      id: z.string(),
    }),
  }),
  requestSchemaStrictValidation: true,
}

const UpdateSmartPhraseReqRes = {
  req: z.object({
    params: z.object({
      smartPhraseId: z.string(),
    }),
    body: z
      .object({
        employeeId: nonEmptyString,
        name: nonEmptyString,
        categoryId: z.string().nullable(),
        text: nonEmptyString,
      })
      .partial(),
  }),
  res: z.object({
    data: z.null(),
  }),
  requestSchemaStrictValidation: true,
}

const DeleteSmartPhraseReqRes = {
  req: z.object({
    params: z.object({
      smartPhraseId: z.string(),
    }),
  }),
  res: z.object({
    data: z.null(),
  }),
}

const GetSmartPhraseCategoriesReqRes = {
  req: z.object({}),
  res: z.object({
    data: z.array(
      z.object({
        oid: z.string(),
        employeeId: z.string(),
        name: z.string(),
      }),
    ),
  }),
}

const CreateSmartPhraseCategoryReqRes = {
  req: z.object({
    body: z.object({
      name: nonEmptyString,
    }),
  }),
  res: z.object({
    data: z.object({
      id: z.string(),
    }),
  }),
}

const UpdateSmartPhraseCategoryReqRes = {
  req: z.object({
    params: z.object({
      smartPhraseCategoryId: z.string(),
    }),
    body: z.object({
      name: nonEmptyString,
    }),
  }),
  res: z.object({
    data: z.null(),
  }),
}

const DeleteSmartPhraseCategoryReqRes = {
  req: z.object({
    params: z.object({
      smartPhraseCategoryId: z.string(),
    }),
  }),
  res: z.object({
    data: z.null(),
  }),
}

export const SmartPhrasesRouteSchemas = {
  'GET /smart-phrases': ListSmartPhrasesReqRes,
  'GET /smart-phrases/:smartPhraseId': GetSmartPhraseReqRes,
  'POST /smart-phrases': CreateSmartPhraseReqRes,
  'PUT /smart-phrases/:smartPhraseId': UpdateSmartPhraseReqRes,
  'DELETE /smart-phrases/:smartPhraseId': DeleteSmartPhraseReqRes,
  'GET /smart-phrase-categories': GetSmartPhraseCategoriesReqRes,
  'POST /smart-phrase-categories': CreateSmartPhraseCategoryReqRes,
  'PUT /smart-phrase-categories/:smartPhraseCategoryId': UpdateSmartPhraseCategoryReqRes,
  'DELETE /smart-phrase-categories/:smartPhraseCategoryId': DeleteSmartPhraseCategoryReqRes,
} satisfies ApiSchemas
