import { useForm } from '@mantine/form'
import {
  ArrowLeftIcon,
  Group,
  PrimaryButton,
  Radio,
  RadioGroup,
  Stack,
  TertiaryButton,
  Text,
  TitleTwo,
  useLifecycle,
  validateWith,
} from '@shared/components'
import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { ContactSupport } from '../../../common/components'
import { isRequired } from '../../../common/forms'
import * as FullStory from '../../../common/fullstory'
import { usePortalDims } from '../../../common/hooks'
import { sendPageEvent } from '../../../common/rudderstack'

type RecoveryType = 'update-phone' | 'update-phone-and-email'

type AccountRecoveryForm = {
  recovery_type: RecoveryType
}

export const AccountRecoveryOptions = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { isMobile } = usePortalDims()

  const form = useForm<AccountRecoveryForm>({
    validate: {
      recovery_type: validateWith(isRequired),
    },
  })

  useLifecycle({
    onMount: () => {
      sendPageEvent('Account Recovery Options')
      FullStory.event('Account Recovery Options Visible')
    },
  })

  const onSubmit = () => {
    if (!form.validate) {
      return
    }

    if (form.values.recovery_type === 'update-phone') {
      // Navigate to sign in with account recovery copy
      const searchParams = new URLSearchParams(location.search)
      searchParams.set('step', 'phone')
      searchParams.set('recovery', '1')

      navigate({
        pathname: location.pathname,
        search: searchParams.toString(),
      })
    } else {
      const searchParams = new URLSearchParams(location.search)
      searchParams.set('step', 'account-recovery-contact')

      navigate({
        pathname: location.pathname,
        search: searchParams.toString(),
      })
    }
  }

  return (
    <Stack>
      <TitleTwo>{`Let's recover your account`}</TitleTwo>
      <Text>Do you have access to the email address associated with your account?</Text>
      <RadioGroup {...form.getInputProps('recovery_type')}>
        <Radio label='Yes' value='update-phone' />
        <Radio label={`No / I don't know`} value='update-phone-and-email' />
      </RadioGroup>
      <Group position='apart'>
        <TertiaryButton
          test-id='button:back@desktop'
          leftIcon={<ArrowLeftIcon />}
          onClick={() => navigate(-1)}
        >
          Back
        </TertiaryButton>
        <PrimaryButton onClick={onSubmit}>Submit</PrimaryButton>
      </Group>

      {isMobile && (
        <Stack align='center' justify='center'>
          <ContactSupport />
        </Stack>
      )}
    </Stack>
  )
}
