// eslint-disable-next-line no-restricted-imports
import { Box, Group } from '@mantine/core'
import React, { forwardRef } from 'react'
import { Select, SelectProps } from './Select'
import { Text } from './typography'

export type SurveyFormType = {
  response: string
  freeForm: string
}

export type SurveyDataItem = {
  readonly label: string
  readonly value: string
}

export type SurveySelectProps = { data: readonly SurveyDataItem[] } & SelectProps

export const SurveySelect = ({ data, ...props }: SurveySelectProps) => {
  return <Select data={data} itemComponent={SurveySelectItem} {...props} />
}

export type SurveySelectItemProps = {
  value: string
  description: string
  label: string
}

const SurveySelectItem = forwardRef<HTMLDivElement, SurveySelectItemProps>(({ ...others }, ref) => {
  const { label, ...props } = others

  return (
    <Box {...props} ref={ref} style={{ width: '100%' }}>
      <Group position='apart' sx={{ width: '100%' }}>
        <Text w='100%' style={{ whiteSpace: 'pre-wrap' }}>
          {label}
        </Text>
      </Group>
    </Box>
  )
})

SurveySelectItem.displayName = 'SurveySelectItem'
