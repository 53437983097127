import { useForm } from '@mantine/form'
import {
  ArrowLeftIcon,
  Group,
  PrimaryButton,
  Radio,
  RadioGroup,
  ResponsiveBox,
  Stack,
  TertiaryButton,
  TitleTwo,
  validateWith,
} from '@shared/components'
import { PromptResponseContextKey } from '@shared/types'
import React from 'react'
import { isRequired } from '../../../forms'
import { useIsQualifiedForCareTransfer } from '../../../hooks/use-is-qualified-for-expedited-care-transfer'
import { WidgetProps } from '../PromptContentWidget'

type SuboxonePrescribedByForm = {
  prescribedBy: PromptResponseContextKey | undefined
}

export const SuboxonePrescribedBy = ({ response, onBack, onSubmit }: WidgetProps) => {
  const { isQualifiedForCareTransfer } = useIsQualifiedForCareTransfer()

  const suboxonePrescribedByForm = useForm<SuboxonePrescribedByForm>({
    initialValues: {
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
      prescribedBy: response?.value.contextKey as PromptResponseContextKey | undefined,
    },
    validate: {
      prescribedBy: validateWith(isRequired),
    },
  })

  const submitHandler = () => {
    const { hasErrors } = suboxonePrescribedByForm.validate()

    if (hasErrors) {
      return
    }
    if (!suboxonePrescribedByForm.values.prescribedBy) {
      return
    }

    if (
      suboxonePrescribedByForm.values.prescribedBy ===
        'suboxone_prescribed_by_medical_professional' &&
      isQualifiedForCareTransfer
    ) {
      onSubmit({
        value: {
          contextKey: 'suboxone_prescribed_by_medical_professional_care_transfer',
        },
      })
    } else {
      onSubmit({
        value: {
          contextKey: suboxonePrescribedByForm.values.prescribedBy,
        },
      })
    }
  }

  return (
    <Stack spacing='lg' test-id='content:suboxone-prescribed-by'>
      <TitleTwo>Is your Suboxone prescribed by a medical professional?</TitleTwo>
      <RadioGroup {...suboxonePrescribedByForm.getInputProps('prescribedBy')}>
        <Radio
          test-id='radio:suboxone-prescribed-by:yes'
          label='Yes'
          value='suboxone_prescribed_by_medical_professional'
        />
        <Radio
          test-id='radio:suboxone-prescribed-by:no'
          label='No'
          value='suboxone_prescribed_by_none'
        />
      </RadioGroup>
      <ResponsiveBox
        mobile={
          <Stack spacing='lg' justify='center'>
            <PrimaryButton test-id='button:next@mobile' fullWidth onClick={submitHandler}>
              Next
            </PrimaryButton>
            {onBack && (
              <TertiaryButton
                test-id='button:back@mobile'
                leftIcon={<ArrowLeftIcon />}
                onClick={onBack}
              >
                Back
              </TertiaryButton>
            )}
          </Stack>
        }
        desktop={
          <Group position='apart'>
            {onBack && (
              <TertiaryButton
                test-id='button:back@desktop'
                leftIcon={<ArrowLeftIcon />}
                onClick={onBack}
              >
                Back
              </TertiaryButton>
            )}
            <Group position='right'>
              <PrimaryButton test-id='button:next@desktop' onClick={submitHandler}>
                Next
              </PrimaryButton>
            </Group>
          </Group>
        }
      />
    </Stack>
  )
}
