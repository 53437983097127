import { useForm } from '@mantine/form'
import {
  Checkbox,
  CheckboxGroup,
  Stack,
  TextInput,
  TitleTwo,
  isAnySelected,
  skipIfOtherField,
  validateWith,
} from '@shared/components'
import { PromptResponseContextKey } from '@shared/types'
import React from 'react'
import { isAtleastOneWord, isRequired } from '../../../forms'
import { PromptContentNavigation } from '../PromptContentNavigation'
import { WidgetProps } from '../PromptContentWidget'

const REASONS_FOR_NEW_PROVIDER_OPTIONS = {
  telehealth: 'telehealth',
  quality_of_care: 'quality_of_care',
  financial_reasons: 'financial_reasons',
  current_provider_unavailable: 'current_provider_unavailable',
  other: 'other',
} as const

export type ReasonForNewProviderOptions = keyof typeof REASONS_FOR_NEW_PROVIDER_OPTIONS

const reasonForNewProviderTextMapping = {
  telehealth: {
    issue: 'Looking for telehealth care',
    response: `Ophelia is 100% online. You can take all appointments from home — no travel, no waiting rooms.`,
  },
  financial_reasons: {
    issue: 'Worried about insurance or money',
    response: `We'll help you check your insurance and find financial options that work for you.`,
  },
  quality_of_care: {
    issue: 'Frustrated with current treatment',
    response: `Our team is here to listen. You'll get compassionate and judgment free support.`,
  },
  current_provider_unavailable: {
    issue: `Can't see current provider/No available appointments`,
    response:
      'Making appointments is easy! You can book online and text us 7 days a week, even on weekends.',
  },
} as const

type ReasonForNewProviderFormType = {
  reasons_for_new_provider: string[]
  other: string
}

export const ReasonForNewProvider = ({ response, onBack, onSubmit }: WidgetProps) => {
  const initialResponseValues = response?.value.contextKey as PromptResponseContextKey[]

  const form = useForm<ReasonForNewProviderFormType>({
    initialValues: {
      reasons_for_new_provider: initialResponseValues || ([] as PromptResponseContextKey[]),
      other: (response?.reason_for_new_provider?.value || '') as PromptResponseContextKey,
    },
    validate: {
      reasons_for_new_provider: isAnySelected(
        Object.keys(REASONS_FOR_NEW_PROVIDER_OPTIONS),
        'Required',
      ),
      other: validateWith(
        skipIfOtherField('reasons_for_new_provider', 'omit', ['other']),
        isRequired,
        isAtleastOneWord,
      ),
    },
  })

  const submitHandler = () => {
    if (form.validate().hasErrors) {
      return
    }
    if (
      !form.values.reasons_for_new_provider.includes(REASONS_FOR_NEW_PROVIDER_OPTIONS.other) &&
      form.values.other
    ) {
      form.setFieldValue('other', '')
    }

    onSubmit({
      value: {
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
        contextKey: form.values.reasons_for_new_provider as PromptResponseContextKey[],
      },
      reason_for_new_provider: {
        contextKey: 'custom',
        value: form.values.reasons_for_new_provider.includes('other') ? form.values.other : '',
      },
    })
  }

  return (
    <Stack spacing='md' test-id='content:motivations-for-treatment-form'>
      <TitleTwo>Can you tell us why you’re looking for a new Suboxone provider?</TitleTwo>
      <CheckboxGroup {...form.getInputProps('reasons_for_new_provider')}>
        {Object.entries(reasonForNewProviderTextMapping).map(([key, { issue, response }]) => (
          <Stack spacing='sm' key={key}>
            <Checkbox
              value={REASONS_FOR_NEW_PROVIDER_OPTIONS[key as ReasonForNewProviderOptions]}
              label={issue}
              explanation={
                form.values.reasons_for_new_provider.includes(key) ? response : undefined
              }
            />
          </Stack>
        ))}
        <Checkbox value={REASONS_FOR_NEW_PROVIDER_OPTIONS.other} label='Other' />
        {form.values.reasons_for_new_provider.includes(REASONS_FOR_NEW_PROVIDER_OPTIONS.other) && (
          <TextInput
            {...form.getInputProps(REASONS_FOR_NEW_PROVIDER_OPTIONS.other)}
            placeholder='Type here...'
          />
        )}
      </CheckboxGroup>
      <PromptContentNavigation onBack={onBack} onSubmit={submitHandler} />
    </Stack>
  )
}
