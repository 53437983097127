import { useForm } from '@mantine/form'
import {
  DateInput,
  Grid,
  Stack,
  TertiaryButton,
  Text,
  TextInput,
  validateWith,
} from '@shared/components'
import { PromptResponseContextKey } from '@shared/types'
import { INSURANCE_OTHER_OPTION, getPayersShortList, validCharacters } from '@shared/utils'
import { default as React, useMemo } from 'react'
import { isBirthday, isRequired } from '../../../forms'
import { getSessionStorageItem } from '../../../storage'
import { InsuranceSelect } from '../../InsuranceSelect'
import { PromptContentNavigation } from '../PromptContentNavigation'
import { WidgetProps } from '../PromptContentWidget'

type InsuranceVerificationForm = {
  insuranceProvider: string
  cardholderMemberId: string
  cardholderFirstName: string
  cardholderLastName: string
  cardholderBirthday: string
}

const NO_INSURANCE_INFO_AVAILABLE = 'no_insurance_info_available' as PromptResponseContextKey

export const InsuranceVerification = ({ onBack, onSubmit, response }: WidgetProps) => {
  const residenceState = getSessionStorageItem('residence_state') || ''
  const insurances = useMemo(() => getPayersShortList(residenceState), [residenceState])

  const form = useForm<InsuranceVerificationForm>({
    initialValues: {
      insuranceProvider:
        getSessionStorageItem('insurance_provider') ||
        (response?.insurance_provider_care_transfer?.value as string) ||
        '',
      cardholderMemberId: (response?.cardholder_member_id?.value as string) ?? '',
      cardholderFirstName: (response?.cardholder_first_name?.value as string) ?? '',
      cardholderLastName: (response?.cardholder_last_name?.value as string) ?? '',
      cardholderBirthday: (response?.cardholder_birthday?.value as string) ?? '',
    },
    validate: {
      insuranceProvider: validateWith(isRequired),
      cardholderMemberId: validateWith(isRequired),
      cardholderFirstName: validateWith(isRequired),
      cardholderLastName: validateWith(isRequired),
      cardholderBirthday: validateWith(isRequired, isBirthday),
    },
  })

  const insuranceOtherOptionValue = `${INSURANCE_OTHER_OPTION.value}__${INSURANCE_OTHER_OPTION.label}`
  const hasSelectedInsuranceFromShortlist =
    form.values.insuranceProvider && form.values.insuranceProvider !== insuranceOtherOptionValue
  const hasSelectedOtherInsurance = form.values.insuranceProvider === insuranceOtherOptionValue

  const submitHandler = ({ hasInsurance }: { hasInsurance?: boolean }) => {
    if (!hasInsurance) {
      return onSubmit({
        value: {
          contextKey: NO_INSURANCE_INFO_AVAILABLE,
        },
      })
    }

    if (form.validate().hasErrors) {
      return
    }

    onSubmit({
      value: {
        contextKey: 'custom',
      },
      insurance_provider_care_transfer: {
        contextKey: 'custom',
        value: form.values.insuranceProvider,
      },
      cardholder_birthday: {
        contextKey: 'custom',
        value: form.values.cardholderBirthday,
      },
      cardholder_first_name: {
        contextKey: 'custom',
        value: form.values.cardholderFirstName,
      },
      cardholder_last_name: {
        contextKey: 'custom',
        value: form.values.cardholderLastName,
      },
      cardholder_member_id: {
        contextKey: 'custom',
        value: form.values.cardholderMemberId,
      },
    })
  }

  return (
    <Stack spacing='lg' test-id='content:how-to-get-started'>
      <Text>We need your primary insurance information to match you with a clinician.</Text>

      <Grid>
        <Grid.Col span={12}>
          <InsuranceSelect
            disabled
            test-id='input:insurance'
            success={hasSelectedInsuranceFromShortlist && `We're in-network with your insurance`}
            warning={
              hasSelectedOtherInsurance && `We'll review your insurance on your welcome call`
            }
            data={insurances}
            {...form.getInputProps('insuranceProvider')}
            placeholder='Type to find your plan...'
            rightSection={<> </>}
            searchable
            clearable
          />
        </Grid.Col>
        <Grid.Col span={12} md={6}>
          <TextInput
            test-id='input:cardholder-first-name'
            placeholder='First name'
            label='Cardholder first name'
            formatter={validCharacters.name}
            maxLength={64}
            {...form.getInputProps('cardholderFirstName')}
          />
        </Grid.Col>
        <Grid.Col span={12} md={6}>
          <TextInput
            test-id='input:cardholder-last-name'
            placeholder='Last name'
            label='Cardholder last name'
            formatter={validCharacters.name}
            maxLength={64}
            {...form.getInputProps('cardholderLastName')}
          />
        </Grid.Col>
        <Grid.Col span={12} md={6}>
          <TextInput
            test-id='input:member-id'
            placeholder='XXXXXXXXXX'
            label='Member ID'
            maxLength={64}
            {...form.getInputProps('cardholderMemberId')}
          />
        </Grid.Col>
        <Grid.Col span={12} md={6}>
          <DateInput
            test-id='input:-cardholder-birthday'
            label='Birthday'
            {...form.getInputProps('cardholderBirthday')}
            maxLength={12}
          />
        </Grid.Col>
      </Grid>
      <TertiaryButton
        test-id='button:back@mobile'
        fullWidth
        onClick={() => submitHandler({ hasInsurance: false })}
      >
        I don&apos;t have my insurance card with me
      </TertiaryButton>
      <PromptContentNavigation
        onBack={onBack}
        onSubmit={() => submitHandler({ hasInsurance: true })}
      />
    </Stack>
  )
}
